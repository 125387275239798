/* eslint-disable no-loop-func */
import {
	Alert,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	IconButton,
	MenuItem,
	Tooltip,
	Typography,
} from '@mui/material'
import { StyledTextBox } from '../../../components/shared/styledComponents/StyledTextBox/StyledTextBox'
import { useNavigate, useParams } from 'react-router'
import { useEffect, useState } from 'react'
import {
	showErrorToast,
	showSuccessToast,
	toAlphaString,
	toBeta,
} from '../../../utils/helperFunctions/helperFunctions'
import PostAndRetrieveDataHook from '../../../utils/customHooks/APICalls/PostAndRetrieveDataHook'
import {
	Customer,
	CustomerTrunkGroupIP,
	TrunkType,
	RegionDataCentre,
	TrunkGroup,
	Order,
	OrderSKU,
	ActionLog,
} from '../../../utils/interfaces/DBModels'

import {
	InternetProtocols,
	RegionOptions,
	TrunkTypes,
	ServiceTypes,
} from '../../../utils/enums/enums'
import './AddTrunk.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { useAppDispatch } from '../../../store/hooks'
import { setPageTitle } from '../../../store/reducers/reducers'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PublicIcon from '@mui/icons-material/Public'
import InfoIcon from '@mui/icons-material/Info'
import { isIPAddress } from 'ip-address-validator'
import { LoadingButton } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'
import { StyledChip } from '../../../components/shared/styledComponents/StyledChip/StyledChip'
import { TrunkIPListDisplay } from '../../../utils/interfaces/ComponentModels'
import LoadingCard from '../../../components/shared/loading/loadingCard/LoadingCard'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
	BetaObject,
	ServiceDetail,
	ServiceRegionRequest,
} from '../../../utils/interfaces/APIModels'
import {
	usePostBetaObjectAddServiceRegionsMutation,
	usePostBetaObjectRemoveServiceRegionsMutation,
} from '../../../services/proxyAPIData'
import DeleteIcon from '@mui/icons-material/Delete'
import { StyledSelect } from '../../../components/shared/styledComponents/StyledSelect/StyledSelect'

function AddTrunk() {
	// Params
	const { customerID } = useParams()

	// Global variables
	const pageTitle = useSelector(
		(state: RootState) => state.RootReducer.pageTitleReducer.value
	)
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const orderSKUIDForTrunk = useSelector(
		(state: RootState) => state.RootReducer.orderSKUIDForTrunkReducer.value
	)

	// Hooks
	const {
		postAndRetrieveDataFromDB,
		postAndRetrieveDataError,
		postAndRetrieveDataLoading,
	} = PostAndRetrieveDataHook()

	const [removeTrunks] = usePostBetaObjectRemoveServiceRegionsMutation()

	// API Calls
	const [addServiceTrunk] = usePostBetaObjectAddServiceRegionsMutation()

	// ********************************* Display constants ********************************* //
	// General
	const [alertMessage, setAlertMessage] = useState('')
	const [customerName, setCustomerName] = useState('')
	const [bizOrg, setBizOrg] = useState('')
	const [regionDataCentreList, setRegionDataCentreList] = useState(
		[] as RegionDataCentre[]
	)
	const [trunkTypeList, setTrunkTypeList] = useState([] as TrunkType[])
	const [customerTrunkGroupIPs, setCustomerTrunkGroupIPs] = useState(
		[] as CustomerTrunkGroupIP[]
	)

	// AMER
	const [amerExists, setAMERExists] = useState(false)
	const [assignAMER, setAssignAMER] = useState(false)
	const [amerCarrierDescription, setAMERCarrierDescription] = useState('')
	const [amerCarrierDescriptionError, setAMERCarrierDescriptionError] =
		useState('')
	const [amerIP, setAMERIP] = useState('')
	const [amerIPError, setAMERIPError] = useState('')
	const [amerTrunkIPList, setAMERTrunkIPList] = useState(
		[] as TrunkIPListDisplay[]
	)
	const [loadingAMER, setLoadingAMER] = useState(false)
	const [selectedAMERDataCentres, setSelectedAMERDataCentres] = useState<
		string[]
	>([])

	// APAC
	const [apacExists, setAPACExists] = useState(false)
	const [assignAPAC, setAssignAPAC] = useState(false)
	const [apacCarrierDescription, setAPACCarrierDescription] = useState('')
	const [apacCarrierDescriptionError, setAPACCarrierDescriptionError] =
		useState('')
	const [apacIP, setAPACIP] = useState('')
	const [apacIPError, setAPACIPError] = useState('')
	const [apacTrunkIPList, setAPACTrunkIPList] = useState(
		[] as TrunkIPListDisplay[]
	)
	const [loadingAPAC, setLoadingAPAC] = useState(false)
	const [selectedAPACDataCentres, setSelectedAPACDataCentres] = useState<
		string[]
	>([])

	// EMEA
	const [emeaExists, setEMEAExists] = useState(false)
	const [assignEMEA, setAssignEMEA] = useState(false)
	const [emeaCarrierDescription, setEMEACarrierDescription] = useState('')
	const [emeaCarrierDescriptionError, setEMEACarrierDescriptionError] =
		useState('')
	const [emeaIP, setEMEAIP] = useState('')
	const [emeaIPError, setEMEAIPError] = useState('')
	const [emeaTrunkIPList, setEMEATrunkIPList] = useState(
		[] as TrunkIPListDisplay[]
	)
	const [loadingEMEA, setLoadingEMEA] = useState(false)
	const [selectedEMEADataCentres, setSelectedEMEADataCentres] = useState<
		string[]
	>([])

	// Dialog
	const [dialogTitle, setDialogTitle] = useState('')
	const [dialogDescription, setDialogDescription] = useState('')

	// ********************************* END Display constants ********************************* //

	// Flags
	const [trunkCallMade, setTrunkCallMade] = useState(false)
	const [existingBYOC, setExistingBYOC] = useState(false)
	const [trunkGroupID, setTrunkGroupID] = useState('')
	const [trunkGroup, setTrunkGroup] = useState({} as TrunkGroup)
	const [apiCallsDone, setAPICallsDone] = useState(false)
	const [openDialog, setOpenDialog] = useState(false)
	const [regionToDelete, setRegionToDelete] = useState('')
	const [addLoading, setAddLoading] = useState(false)
	const [deleteAllLoading, setDeleteAllLoading] = useState(false)
	const [deleteAMERLoading, setDeleteAMERLoading] = useState(false)
	const [deleteAPACLoading, setDeleteAPACLoading] = useState(false)
	const [deleteEMEALoading, setDeleteEMEALoading] = useState(false)
	const [trunkGroupQuantity, setTrunkGroupQuantity] = useState(0)

	// General
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	useEffect(() => {
		// Assign page title
		if (pageTitle !== 'Add Trunk(s)') {
			dispatch(setPageTitle('Add Trunk(s)'))
		}

		if (customerID && customerID.length > 0) {
			// Make API call
			if (!postAndRetrieveDataLoading) {
				if (!trunkCallMade) {
					setTrunkCallMade(true)
					checkForCustomerAndExistingTrunkGroup()
				}
			}
		} else {
			navigate('/')
			showErrorToast('There was no customerID found. Please try again.')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		customerID,
		postAndRetrieveDataLoading,
		postAndRetrieveDataError,
		// Flags
		trunkCallMade,
		amerExists,
		apacExists,
		emeaExists,
	])

	// *************************************** API Calls *************************************** //

	// GET: Check for existing BYOC trunk group
	const checkForCustomerAndExistingTrunkGroup = async () => {
		// Make API Call to return trunks and check if a BYOC exists
		var dataResponse = await postAndRetrieveDataFromDB(
			'Customer and Trunk Groups',
			`Customer.Order.OrderSKU.Where(Customer.CustomerID = '${customerID}' & OrderSKU.SKUTypeID ^ 'VC-DR-SIP-SER-BAS,VC-TEAMS-MANG-DEP-UC'), TrunkGroup.CustomerTrunkGroupIP.CustomerTrunkGroupRegionMap.Includes(TrunkGroup.CustomerID = '${customerID}'), RegionDataCentre.DataCentre.SBCVirtualIPDataCentre.SBCVirtualIP.All(), TrunkType.All(), ActionLog.First(ActionLog.CustomerID = '${customerID}' & ActionLog.OrderSKUID = '${orderSKUIDForTrunk}' & ActionLog.ProductID ^ 'BAS-BYOC, BYOC'))`,
			false,
			true
		)

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			// Assign customer
			var customerResponse = dataResponse.Obj.CustomerList[0] as Customer

			if (customerResponse && customerResponse.CustomerName) {
				setCustomerName(customerResponse.CustomerName)

				if (customerResponse.CustomerPartnerReference) {
					setBizOrg(customerResponse.CustomerPartnerReference)
				}

				// Get total number of seats for VC SKUS to use as trunk Quantity if creating new trunkGroup
				var customerOrdersRespone = customerResponse.OrderList as Order[]
				if (customerOrdersRespone && customerOrdersRespone.length > 0) {
					var vcOrderSKUS: OrderSKU[] = []
					for (let i = 0; i < customerOrdersRespone.length; i++) {
						if (customerOrdersRespone[i].OrderSKUList) {
							vcOrderSKUS = vcOrderSKUS.concat(
								customerOrdersRespone[i].OrderSKUList as OrderSKU[]
							)
						}
					}
					var totalNumOfSeats = 0
					for (let i = 0; i < vcOrderSKUS.length; i++) {
						totalNumOfSeats += Number(vcOrderSKUS[i].Quantity)
					}
					setTrunkGroupQuantity(totalNumOfSeats)
				}
			}

			// Assign region data centre list
			var regionDataCentreResponse = dataResponse.Obj
				.RegionDataCentreList as RegionDataCentre[]

			if (regionDataCentreResponse && regionDataCentreResponse.length > 0) {
				setRegionDataCentreList(regionDataCentreResponse)
			}

			//  Assign Trunk Type list
			var trunkTypeResponse = dataResponse.Obj.TrunkTypeList as TrunkType[]
			if (trunkTypeResponse && trunkTypeResponse.length > 0) {
				setTrunkTypeList(trunkTypeResponse)
			}

			// Assign array to check for BYOC and get the latest index if there is no BYOC
			var trunkGroupResponse = dataResponse.Obj.TrunkGroupList as TrunkGroup[]

			if (trunkGroupResponse && trunkGroupResponse.length > 0) {
				// Check for BYOC
				var trunkGroupIDToUse = ''
				var existingBYOC = false

				var actionLogResponse = dataResponse.Obj.ActionLog as ActionLog
				if (
					actionLogResponse &&
					actionLogResponse.ActionLogID &&
					(actionLogResponse.Data ?? '').length > 0
				) {
					// If BYOC exists, get the trunk group ID to use
					trunkGroupIDToUse = actionLogResponse.Data + ''
				}

				var byoc
				if (trunkGroupIDToUse.length > 0) {
					// Existing BYOC
					byoc = trunkGroupResponse.find(
						(t) => t.TrunkGroupID === trunkGroupIDToUse
					)
				} else {
					// need to check this logic -
					//if trunkGroupIDToUse is empty, then we need to find the BYOC trunkGroupID
					byoc = trunkGroupResponse.find(
						(t) => Number(t.TrunkTypeID) === TrunkTypes.BYOC
					)
				}
				if (byoc && byoc.TrunkGroupID) {
					// Existing BYOC
					setTrunkGroup(byoc)
					existingBYOC = true
					trunkGroupIDToUse = byoc.TrunkGroupID

					// Then loop through the trunk IPs and regions, check which ones exist already
					var customerTrunkGroupIPList = byoc.CustomerTrunkGroupIPList

					if (customerTrunkGroupIPList && customerTrunkGroupIPList.length > 0) {
						setCustomerTrunkGroupIPs(customerTrunkGroupIPList)
						// Check which regions exists for the alert
						var amerAlert = ''
						var apacAlert = ''
						var emeaAlert = ''
						var amerExists = false
						var apacExists = false
						var emeaExists = false
						// Trunk IP IDs
						var amerIPIDList = [] as string[]
						var apacIPIDList = [] as string[]
						var emeaIPIDList = [] as string[]
						// Loop through the IP list
						for (let cti = 0; cti < customerTrunkGroupIPList.length; cti++) {
							// Region Map List - Check which region the IP belongs to
							var regionMapList =
								customerTrunkGroupIPList[cti].CustomerTrunkGroupRegionMapList

							if (regionMapList && regionMapList.length > 0) {
								// AMER
								var isAMERIP = regionMapList.find(
									(amerIP) => Number(amerIP.RegionID) === RegionOptions.AMER
								)
								if (isAMERIP) {
									// Push to ID list
									amerIPIDList.push(
										customerTrunkGroupIPList[cti].CustomerTrunkGroupIPID + ''
									)
									amerAlert = 'AMER'
									amerExists = true
								}
								// APAC
								var isAPACIP = regionMapList.find(
									(apacIP) => Number(apacIP.RegionID) === RegionOptions.APAC
								)
								if (isAPACIP) {
									// Push to ID list
									apacIPIDList.push(
										customerTrunkGroupIPList[cti].CustomerTrunkGroupIPID + ''
									)
									apacAlert = 'APAC'
									apacExists = true
								}
								// EMEA
								var isEMEAIP = regionMapList.find(
									(emeaIP) => Number(emeaIP.RegionID) === RegionOptions.EMEA
								)
								if (isEMEAIP) {
									// Push to ID list
									emeaIPIDList.push(
										customerTrunkGroupIPList[cti].CustomerTrunkGroupIPID + ''
									)
									emeaAlert = 'EMEA'
									emeaExists = true
								}
							}
						}
						// Set Exists
						setAMERExists(amerExists)
						setAPACExists(apacExists)
						setEMEAExists(emeaExists)

						// Alert message
						var regions = [amerAlert, apacAlert, emeaAlert]
							.filter(Boolean)
							.join(', ')
						var alert = `Please note the ${regions} region(s) have been assigned already and cannot be configured again. If you wish to do this, you will need to remove the existing region assigned trunk IPs for this customer.`
						setAlertMessage(alert)

						// Loop through the ID Lists built and build the display list
						// Trunk IP List to build
						var amerTrunkIPList = [] as TrunkIPListDisplay[]
						var apacTrunkIPList = [] as TrunkIPListDisplay[]
						var emeaTrunkIPList = [] as TrunkIPListDisplay[]
						var regionIPToFind = {} as CustomerTrunkGroupIP | undefined
						var amerCarrierDescription = ''
						var apacCarrierDescription = ''
						var emeaCarrierDescription = ''
						// AMER
						if (amerAlert.length > 0 && amerIPIDList.length > 0) {
							// Loop through the IDs and build the IPs
							for (let amer = 0; amer < amerIPIDList.length; amer++) {
								regionIPToFind = customerTrunkGroupIPList.find(
									(c) => c.CustomerTrunkGroupIPID + '' === amerIPIDList[amer]
								)
								if (regionIPToFind) {
									amerTrunkIPList.push({
										CustomerTrunkGroupIPID:
											regionIPToFind.CustomerTrunkGroupIPID,
										CustomerTrunkIP: regionIPToFind.CustomerTrunkIP,
									})
									amerCarrierDescription =
										regionIPToFind.CustomerTrunkIPName + ''
								}
							}
						}
						// APAC
						if (apacAlert.length > 0 && apacIPIDList.length > 0) {
							// Loop through the IDs and build the IPs
							for (let apac = 0; apac < apacIPIDList.length; apac++) {
								regionIPToFind = customerTrunkGroupIPList.find(
									(c) => c.CustomerTrunkGroupIPID + '' === apacIPIDList[apac]
								)
								if (regionIPToFind) {
									apacTrunkIPList.push({
										CustomerTrunkGroupIPID:
											regionIPToFind.CustomerTrunkGroupIPID,
										CustomerTrunkIP: regionIPToFind.CustomerTrunkIP,
									})
									apacCarrierDescription =
										regionIPToFind.CustomerTrunkIPName + ''
								}
							}
						}
						// EMEA
						if (emeaAlert.length > 0 && emeaIPIDList.length > 0) {
							// Loop through the IDs and build the IPs
							for (let emea = 0; emea < emeaIPIDList.length; emea++) {
								regionIPToFind = customerTrunkGroupIPList.find(
									(c) => c.CustomerTrunkGroupIPID + '' === emeaIPIDList[emea]
								)
								if (regionIPToFind) {
									emeaTrunkIPList.push({
										CustomerTrunkGroupIPID:
											regionIPToFind.CustomerTrunkGroupIPID,
										CustomerTrunkIP: regionIPToFind.CustomerTrunkIP,
									})
									emeaCarrierDescription =
										regionIPToFind.CustomerTrunkIPName + ''
								}
							}
						}

						// Set Lists and Objects
						setAMERTrunkIPList(amerTrunkIPList)
						setAPACTrunkIPList(apacTrunkIPList)
						setEMEATrunkIPList(emeaTrunkIPList)
						setAMERCarrierDescription(amerCarrierDescription)
						setAPACCarrierDescription(apacCarrierDescription)
						setEMEACarrierDescription(emeaCarrierDescription)
					} else {
						setAlertMessage('')
						setTrunkGroupID(trunkGroupIDToUse)
						clearArrays()
					}
				} else {
					// If no BYOC found, loop through to get the latest index
					var trunkIndexToUse = 1
					var maxTrunkIndex = 0
					for (let tg = 0; tg < trunkGroupResponse.length; tg++) {
						// If BYPT, then ignore
						if (
							Number(trunkGroupResponse[tg].TrunkTypeID) !== TrunkTypes.BYPT
						) {
							// Split trunk group ID by -
							var trunkGroupIDSplit =
								trunkGroupResponse[tg].TrunkGroupID?.split('-')
							if (trunkGroupIDSplit) {
								if (Number(trunkGroupIDSplit[1]) > maxTrunkIndex) {
									maxTrunkIndex = Number(trunkGroupIDSplit[1])
								}
							}
						}
					}
					// Indent new index
					trunkIndexToUse += maxTrunkIndex
					trunkGroupIDToUse = `SPT${customerID}-${trunkIndexToUse}`
					setAlertMessage('')
					clearArrays()
				}

				// Set values
				setExistingBYOC(existingBYOC)
				setTrunkGroupID(trunkGroupIDToUse)
			} else {
				// If no trunk groups were found, assign a new trunk group ID to use
				setExistingBYOC(false)
				setAlertMessage('')
				setTrunkGroupID(`SPT${customerID}-1`)
				clearArrays()
			}
		}

		// API Call Done
		setAPICallsDone(true)
	}

	// GET: Check for Duplicate IP Address in the DB
	const checkForDuplicateIPAddress = async (ip: string) => {
		// Make API Call to check DB if duplicate
		var dataResponse = await postAndRetrieveDataFromDB(
			'Customer Trunk IP',
			`CustomerTrunkGroupIP.Exists(CustomerTrunkGroupIP.CustomerTrunkIP = '${ip}')`,
			false,
			true
		)
		if (dataResponse && dataResponse.Obj && dataResponse.Obj === true) {
			return false
		} else {
			return true
		}
	}

	// GET: Check for Duplicate IP Address in the DB
	const checkForDuplicateCarrierDescription = async () => {
		// Make API Call to check DB if duplicate
		var valid = true
		if (assignAMER) {
			// API Call
			var amerResponse = await postAndRetrieveDataFromDB(
				'Customer Trunk IP Name',
				`TrunkGroup.CustomerTrunkGroupIP.Exists(TrunkGroup.CustomerID = '${customerID}' & CustomerTrunkGroupIP.CustomerTrunkIPName = '${amerCarrierDescription}')`,
				false,
				true
			)
			if (amerResponse && amerResponse.Obj && amerResponse.Obj === true) {
				valid = false
				setAMERCarrierDescriptionError(
					'The carrier description entered already exists. Please try another.'
				)
			}
		}
		if (assignAPAC) {
			// API Call
			var apacResponse = await postAndRetrieveDataFromDB(
				'Customer Trunk IP Name',
				`TrunkGroup.CustomerTrunkGroupIP.Exists(TrunkGroup.CustomerID = '${customerID}' & CustomerTrunkGroupIP.CustomerTrunkIPName = '${apacCarrierDescription}')`,
				false,
				true
			)
			if (apacResponse && apacResponse.Obj && apacResponse.Obj === true) {
				valid = false
				setAPACCarrierDescriptionError(
					'The carrier description entered already exists. Please try another.'
				)
			}
		}
		if (assignEMEA) {
			// API Call
			var emeaResponse = await postAndRetrieveDataFromDB(
				'Customer Trunk IP Name',
				`TrunkGroup.CustomerTrunkGroupIP.Exists(TrunkGroup.CustomerID = '${customerID}' & CustomerTrunkGroupIP.CustomerTrunkIPName = '${emeaCarrierDescription}')`,
				false,
				true
			)
			if (emeaResponse && emeaResponse.Obj && emeaResponse.Obj === true) {
				valid = false
				setEMEACarrierDescriptionError(
					'The carrier description entered already exists. Please try another.'
				)
			}
		}

		return valid
	}

	// POST: Add new Trunk Group/Add Trunk IPs to existing BYOC
	const addTrunks = async () => {
		// Loading true for add button
		setAddLoading(true)
		var error = false

		// Make API call first to ensure the carrier description does not exist
		var isValidCarrierDescriptions = await checkForDuplicateCarrierDescription()

		if (isValidCarrierDescriptions) {
			var serviceDetails = [] as ServiceDetail[]
			// Ensure the trunk group ID is populated
			if (trunkGroupID && trunkGroupID.length > 0) {
				// Check which region(s) are being assigned
				if (assignAMER || assignAPAC || assignEMEA) {
					// AMER
					if (assignAMER) {
						// Generate the IP List with the regions
						for (let amer = 0; amer < amerTrunkIPList.length; amer++) {
							// Add to Service Details
							var amerServiceDetail = {} as ServiceDetail
							amerServiceDetail.customer_trunk_ip_name = amerCarrierDescription
							amerServiceDetail.ip = amerTrunkIPList[amer].CustomerTrunkIP
							amerServiceDetail.regions = [RegionOptions[RegionOptions.AMER]]
							amerServiceDetail.port = '5060'
							amerServiceDetail.protocol =
								InternetProtocols[InternetProtocols.UDP]
							amerServiceDetail.data_centers =
								amerTrunkIPList[amer].DataCentres?.map((dc) => ({
									[`${dc}`]: '',
								})) || []

							serviceDetails.push(amerServiceDetail)
						}
					}
					// APAC
					if (assignAPAC) {
						// Generate the IP List with the regions
						for (let apac = 0; apac < apacTrunkIPList.length; apac++) {
							// Add to Service Details
							var apacServiceDetail = {} as ServiceDetail
							apacServiceDetail.customer_trunk_ip_name = apacCarrierDescription
							apacServiceDetail.ip = apacTrunkIPList[apac].CustomerTrunkIP
							apacServiceDetail.regions = [RegionOptions[RegionOptions.APAC]]
							apacServiceDetail.port = '5060'
							apacServiceDetail.protocol =
								InternetProtocols[InternetProtocols.UDP]
							apacServiceDetail.data_centers =
								apacTrunkIPList[apac].DataCentres?.map((dc) => ({
									[`${dc}`]: '',
								})) || []

							serviceDetails.push(apacServiceDetail)
						}
					}
					// EMEA
					if (assignEMEA) {
						// Generate the IP List with the regions
						for (let emea = 0; emea < emeaTrunkIPList.length; emea++) {
							// Add to Service Details
							var emeaServiceDetail = {} as ServiceDetail
							emeaServiceDetail.customer_trunk_ip_name = emeaCarrierDescription
							emeaServiceDetail.ip = emeaTrunkIPList[emea].CustomerTrunkIP
							emeaServiceDetail.regions = [RegionOptions[RegionOptions.EMEA]]
							emeaServiceDetail.port = '5060'
							emeaServiceDetail.protocol =
								InternetProtocols[InternetProtocols.UDP]
							emeaServiceDetail.data_centers =
								emeaTrunkIPList[emea].DataCentres?.map((dc) => ({
									[`${dc}`]: '',
								})) || []

							serviceDetails.push(emeaServiceDetail)
						}
					}

					// Check if there are any service details
					var serviceTrunkRequest: ServiceRegionRequest = {
						customer_id: customerID + '',
						order_sku_id: orderSKUIDForTrunk,
						service_id: trunkGroupID,
						service_type: ServiceTypes[ServiceTypes.BYOC],
						user_email: loggedInUser.email,
						service_details: serviceDetails,
					}
					error = !(await addApplicationRegionsOrTrunks(serviceTrunkRequest))
				}
			}

			// Make call to get latest data and show success message if no errors occurred
			if (!error) {
				checkForCustomerAndExistingTrunkGroup()
				resetVariables()
				showSuccessToast('Trunks added successfully')
			}
		}

		// Stop loading for add button
		setAddLoading(false)
	}

	const addApplicationRegionsOrTrunks = async (
		data: ServiceRegionRequest
	): Promise<boolean> => {
		var isSuccessful = false
		var betaDataObj = await toBeta(data)

		var betaObj: BetaObject = {
			Content: betaDataObj,
		}

		await addServiceTrunk(betaObj)
			.unwrap()
			.then(async () => {
				isSuccessful = true
			})
			.catch(async (error) => {
				var errorDataResponse = await toAlphaString(error?.data?.Content + '')

				if (
					errorDataResponse &&
					errorDataResponse.startsWith('"Validation error:')
				) {
					errorDataResponse = errorDataResponse.replace(
						'"Validation error:',
						''
					)
					showErrorToast(errorDataResponse)
				} else {
					showErrorToast(`Failed to add trunks`)
				}
			})
		return isSuccessful
	}

	// POST: Delete the trunk group/trunk IPs
	const deleteTrunks = async () => {
		// Change loading display
		handleDeleteDisplay()
		// Check what is being deleted
		var deleteAll = false
		var deleteAMER = false
		var deleteAPAC = false
		var deleteEMEA = false
		var trunkRegions: string[] = []

		switch (regionToDelete) {
			case 'ALL':
				deleteAll = true
				if (amerExists) {
					trunkRegions.push(RegionOptions[RegionOptions.AMER])
				}
				if (apacExists) {
					trunkRegions.push(RegionOptions[RegionOptions.APAC])
				}
				if (emeaExists) {
					trunkRegions.push(RegionOptions[RegionOptions.EMEA])
				}

				break

			case 'AMER':
				trunkRegions.push(RegionOptions[RegionOptions.AMER])
				if (amerExists && !apacExists && !emeaExists) {
					deleteAll = true
				} else {
					deleteAMER = true
				}
				break

			case 'APAC':
				trunkRegions.push(RegionOptions[RegionOptions.APAC])
				if (!amerExists && apacExists && !emeaExists) {
					deleteAll = true
				} else {
					deleteAPAC = true
				}
				break

			case 'EMEA':
				trunkRegions.push(RegionOptions[RegionOptions.EMEA])
				if (!amerExists && !apacExists && emeaExists) {
					deleteAll = true
				} else {
					deleteEMEA = true
				}
				break
		}

		var _serviceDetails = [] as ServiceDetail[]
		if (deleteAll) {
			// Delete all Trunk IPs, Regions and FQDNs
			if (amerTrunkIPList && amerTrunkIPList.length > 0) {
				for (let amer = 0; amer < amerTrunkIPList.length; amer++) {
					_serviceDetails.push({
						regions: [RegionOptions[RegionOptions.AMER]],
						data_centers: [],
						customer_trunk_group_ip_id:
							amerTrunkIPList[amer].CustomerTrunkGroupIPID,
					})
				}
			}

			if (apacTrunkIPList && apacTrunkIPList.length > 0) {
				for (let apac = 0; apac < apacTrunkIPList.length; apac++) {
					_serviceDetails.push({
						regions: [RegionOptions[RegionOptions.APAC]],
						data_centers: [],
						customer_trunk_group_ip_id:
							apacTrunkIPList[apac].CustomerTrunkGroupIPID,
					})
				}
			}

			if (emeaTrunkIPList && emeaTrunkIPList.length > 0) {
				for (let emea = 0; emea < emeaTrunkIPList.length; emea++) {
					_serviceDetails.push({
						regions: [RegionOptions[RegionOptions.EMEA]],
						data_centers: [],
						customer_trunk_group_ip_id:
							emeaTrunkIPList[emea].CustomerTrunkGroupIPID,
					})
				}
			}
		} else {
			// Delete only the Trunk IPs, Regions and FQDNs and call SBC for each region
			if (deleteAMER) {
				// AMER
				if (amerTrunkIPList && amerTrunkIPList.length > 0) {
					for (let amer = 0; amer < amerTrunkIPList.length; amer++) {
						_serviceDetails.push({
							regions: [RegionOptions[RegionOptions.AMER]],
							data_centers: [],
							customer_trunk_group_ip_id:
								amerTrunkIPList[amer].CustomerTrunkGroupIPID,
						})
					}
				}
			}
			if (deleteAPAC) {
				// APAC
				if (apacTrunkIPList && apacTrunkIPList.length > 0) {
					for (let apac = 0; apac < apacTrunkIPList.length; apac++) {
						_serviceDetails.push({
							regions: [RegionOptions[RegionOptions.APAC]],
							data_centers: [],
							customer_trunk_group_ip_id:
								apacTrunkIPList[apac].CustomerTrunkGroupIPID,
						})
					}
				}
			}
			if (deleteEMEA) {
				// EMEA
				if (emeaTrunkIPList && emeaTrunkIPList.length > 0) {
					for (let emea = 0; emea < emeaTrunkIPList.length; emea++) {
						_serviceDetails.push({
							regions: [RegionOptions[RegionOptions.EMEA]],
							data_centers: [],
							customer_trunk_group_ip_id:
								emeaTrunkIPList[emea].CustomerTrunkGroupIPID,
						})
					}
				}
			}
		}

		// Call to delete the trunk group
		var serviceToRemoveRequest: ServiceRegionRequest = {
			customer_id: customerID + '',
			service_id: trunkGroupID,
			service_type: ServiceTypes[ServiceTypes.BYOC],
			user_email: loggedInUser.email,
			order_sku_id: orderSKUIDForTrunk,
			service_details: _serviceDetails,
		}

		var isSuccessful = await removeConnectivityService(serviceToRemoveRequest)

		if (isSuccessful) {
			checkForCustomerAndExistingTrunkGroup()
			resetVariables()
		}
	}

	const removeConnectivityService = async (
		serviceToRemoveRequest: ServiceRegionRequest
	): Promise<boolean> => {
		var isSuccessful = false
		var betaDataObj = await toBeta(serviceToRemoveRequest)

		var betaObj: BetaObject = {
			Content: betaDataObj,
		}

		await removeTrunks(betaObj)
			.unwrap()
			.then(async () => {
				isSuccessful = true
				showSuccessToast(
					`Trunk(s) removed successfully for ${serviceToRemoveRequest.service_id}`
				)
			})
			.catch(async (error) => {
				var errorDataResponse = await toAlphaString(error?.data?.Content + '')

				if (
					errorDataResponse &&
					errorDataResponse.startsWith('"Validation error:')
				) {
					errorDataResponse = errorDataResponse.replace(
						'"Validation error:',
						''
					)
					showErrorToast(errorDataResponse)
				} else {
					showErrorToast(
						`Failed to remove trunk(s) for ${serviceToRemoveRequest.service_id}`
					)
				}
			})

		return isSuccessful
	}

	// *************************************** END API Calls *************************************** //

	// Handle the loading for delete
	const handleDeleteDisplay = () => {
		// Check what is being deleted
		switch (regionToDelete) {
			// ALL
			case 'ALL':
				setDeleteAllLoading(true)
				break
			// AMER
			case 'AMER':
				setDeleteAMERLoading(true)
				break
			// APAC
			case 'APAC':
				setDeleteAPACLoading(true)
				break
			// EMEA
			case 'EMEA':
				setDeleteEMEALoading(true)
				break
		}

		// Close dialog
		setOpenDialog(false)
	}

	// Reset variables upon add/delete
	const resetVariables = () => {
		// Flags
		setAssignAMER(false)
		setAssignAPAC(false)
		setAssignEMEA(false)
		setDeleteAllLoading(false)
		setDeleteAMERLoading(false)
		setDeleteAPACLoading(false)
		setDeleteEMEALoading(false)
		// Errors
		setAMERCarrierDescriptionError('')
		setAPACCarrierDescriptionError('')
		setEMEACarrierDescriptionError('')
		setAMERIPError('')
		setAPACIPError('')
		setEMEAIPError('')
		// General
		setAMERIP('')
		setAPACIP('')
		setEMEAIP('')
		setSelectedAMERDataCentres([])
		setSelectedAPACDataCentres([])
		setSelectedEMEADataCentres([])
	}

	// Clear arrays - Used for when deleting all
	const clearArrays = () => {
		// Flags
		setAMERExists(false)
		setAPACExists(false)
		setEMEAExists(false)
		// Carrier Descriptions
		setAMERCarrierDescription('')
		setAPACCarrierDescription('')
		setEMEACarrierDescription('')
		// IP value and arrays
		setAMERIP('')
		setAMERTrunkIPList([])
		setAPACIP('')
		setAPACTrunkIPList([])
		setEMEAIP('')
		setEMEATrunkIPList([])
		setSelectedAMERDataCentres([])
		setSelectedAPACDataCentres([])
		setSelectedEMEADataCentres([])
	}

	// Handle functions
	const handleCarrierDescriptionChange = (
		e: { target: any },
		region: string
	) => {
		// Check which region changed
		switch (region) {
			case 'AMER':
				setAMERCarrierDescription(e.target.value)
				break

			case 'APAC':
				setAPACCarrierDescription(e.target.value)
				if (apacCarrierDescriptionError.length > 0) {
					setAPACCarrierDescriptionError('')
				}
				break

			case 'EMEA':
				setEMEACarrierDescription(e.target.value)
				if (emeaCarrierDescriptionError.length > 0) {
					setEMEACarrierDescriptionError('')
				}
				break
		}

		// Validate
		validateCarrierDescription(region, e.target.value)
	}

	// Validate Carrier Description
	const validateCarrierDescription = (region: string, value: string) => {
		// Check which region
		switch (region) {
			// AMER
			case 'AMER':
				if (value && value.length > 0) {
					// Check if does not match any other description
					if (
						value.trim() === apacCarrierDescription.trim() ||
						value.trim() === emeaCarrierDescription.trim()
					) {
						setAMERCarrierDescriptionError(
							'You have entered the same carrier description for another trunk region. Please ensure all trunk carrier descriptions are unique for each region.'
						)
					} else {
						setAMERCarrierDescriptionError('')
					}
				}
				break
			// APAC
			case 'APAC':
				if (value && value.length > 0) {
					// Check if does not match any other description
					if (
						value.trim() === amerCarrierDescription.trim() ||
						value.trim() === emeaCarrierDescription.trim()
					) {
						setAPACCarrierDescriptionError(
							'You have entered the same carrier description for another trunk region. Please ensure all trunk carrier descriptions are unique for each region.'
						)
					} else {
						setAPACCarrierDescriptionError('')
					}
				}
				break
			// EMEA
			case 'EMEA':
				if (value && value.length > 0) {
					// Check if does not match any other description
					if (
						value.trim() === amerCarrierDescription.trim() ||
						value.trim() === apacCarrierDescription.trim()
					) {
						setEMEACarrierDescriptionError(
							'You have entered the same carrier description for another trunk region. Please ensure all trunk carrier descriptions are unique for each region.'
						)
					} else {
						setEMEACarrierDescriptionError('')
					}
				}
				break
		}
	}

	// Save the trunk IP change
	const handleTrunkIPChange = async (e: { target: any }, region: string) => {
		// Check what region changed
		switch (region) {
			case 'AMER':
				setAMERIP(e.target.value)
				if (amerIPError.length > 0) {
					setAMERIPError('')
				}
				break

			case 'APAC':
				setAPACIP(e.target.value)
				if (apacIPError.length > 0) {
					setAPACIPError('')
				}
				break

			case 'EMEA':
				setEMEAIP(e.target.value)
				if (emeaIPError.length > 0) {
					setEMEAIPError('')
				}
				break
		}
	}

	// Handles if the user presses enter after adding an IP
	const handleKeyPress = async (e: any, region: string) => {
		// Check if enter key was pressed
		if (e.keyCode === 13) {
			var error = ''
			switch (region) {
				case 'AMER':
					// Ensure the IP is not empty
					if (amerIP.length > 0) {
						setLoadingAMER(true)
						error = await validateIP(amerIP)
						if (error && error.length > 0) {
							setAMERIPError(error)
						} else {
							if (amerTrunkIPList.length > 0) {
								setAMERTrunkIPList((ips) => [
									...ips,
									{
										CustomerTrunkIP: amerIP,
										DataCentres: selectedAMERDataCentres,
									},
								])
							} else {
								setAMERTrunkIPList([
									{
										CustomerTrunkIP: amerIP,
										DataCentres: selectedAMERDataCentres,
									},
								])
							}
							setAMERIP('')
							setSelectedAMERDataCentres([])
						}
						setLoadingAMER(false)
					}
					break

				case 'APAC':
					// Ensure the IP is not empty
					if (apacIP.length > 0) {
						setLoadingAPAC(true)
						error = await validateIP(apacIP)
						if (error && error.length > 0) {
							setAPACIPError(error)
						} else {
							if (apacTrunkIPList.length > 0) {
								setAPACTrunkIPList((ips) => [
									...ips,
									{
										CustomerTrunkIP: apacIP,
										DataCentres: selectedAPACDataCentres,
									},
								])
							} else {
								setAPACTrunkIPList([
									{
										CustomerTrunkIP: apacIP,
										DataCentres: selectedAPACDataCentres,
									},
								])
							}
							setAPACIP('')
							setSelectedAPACDataCentres([])
						}
						setLoadingAPAC(false)
					}
					break

				case 'EMEA':
					// Ensure the IP is not empty
					if (emeaIP.length > 0) {
						setLoadingEMEA(true)
						error = await validateIP(emeaIP)
						if (error && error.length > 0) {
							setEMEAIPError(error)
						} else {
							if (emeaTrunkIPList.length > 0) {
								setEMEATrunkIPList((ips) => [
									...ips,
									{
										CustomerTrunkIP: emeaIP,
										DataCentres: selectedEMEADataCentres,
									},
								])
							} else {
								setEMEATrunkIPList([
									{
										CustomerTrunkIP: emeaIP,
										DataCentres: selectedEMEADataCentres,
									},
								])
							}
							setEMEAIP('')
							setSelectedEMEADataCentres([])
						}
						setLoadingEMEA(false)
					}
					break
			}
		}
	}

	// Handle if the user clicks the add IP button
	const handleAddIPButton = async (e: any, region: string) => {
		// Check if enter key was pressed
		var error = ''
		switch (region) {
			case 'AMER':
				// Ensure the IP is not empty
				if (amerIP.length > 0) {
					setLoadingAMER(true)
					error = await validateIP(amerIP)
					if (error && error.length > 0) {
						setAMERIPError(error)
					} else {
						if (amerTrunkIPList.length > 0) {
							setAMERTrunkIPList((ips) => [
								...ips,
								{
									CustomerTrunkIP: amerIP,
									DataCentres: selectedAMERDataCentres,
								},
							])
						} else {
							setAMERTrunkIPList([
								{
									CustomerTrunkIP: amerIP,
									DataCentres: selectedAMERDataCentres,
								},
							])
						}
						setAMERIP('')
						setSelectedAMERDataCentres([])
					}
					setLoadingAMER(false)
				}
				break

			case 'APAC':
				// Ensure the IP is not empty
				if (apacIP.length > 0) {
					setLoadingAPAC(true)
					error = await validateIP(apacIP)
					if (error && error.length > 0) {
						setAPACIPError(error)
					} else {
						if (apacTrunkIPList.length > 0) {
							setAPACTrunkIPList((ips) => [
								...ips,
								{
									CustomerTrunkIP: apacIP,
									DataCentres: selectedAPACDataCentres,
								},
							])
						} else {
							setAPACTrunkIPList([
								{
									CustomerTrunkIP: apacIP,
									DataCentres: selectedAPACDataCentres,
								},
							])
						}
						setAPACIP('')
						setSelectedAPACDataCentres([])
					}
					setLoadingAPAC(false)
				}
				break

			case 'EMEA':
				// Ensure the IP is not empty
				if (emeaIP.length > 0) {
					setLoadingEMEA(true)
					error = await validateIP(emeaIP)
					if (error && error.length > 0) {
						setEMEAIPError(error)
					} else {
						if (emeaTrunkIPList.length > 0) {
							setEMEATrunkIPList((ips) => [
								...ips,
								{
									CustomerTrunkIP: emeaIP,
									DataCentres: selectedEMEADataCentres,
								},
							])
						} else {
							setEMEATrunkIPList([
								{
									CustomerTrunkIP: emeaIP,
									DataCentres: selectedEMEADataCentres,
								},
							])
						}
						setEMEAIP('')
						setSelectedEMEADataCentres([])
					}
					setLoadingEMEA(false)
				}
				break
		}
	}

	// Handle validate IP - Check for format and if duplicate
	const validateIP = async (ip: string) => {
		// Check if there is a value
		var error = ''
		if (ip && ip.length > 0) {
			// Check if it is in the valid format
			var isIPValid = isIPAddress(ip)

			if (isIPValid) {
				// Check if IP already exists
				var isNotDuplicate = await checkForDuplicateIPAddress(ip)

				if (!isNotDuplicate) {
					error = 'The Trunk IP entered already exists. Please try another one.'
				}

				// Check if the IP was added for any regions
				var checkAMER = amerTrunkIPList.find(
					(amer) => amer.CustomerTrunkIP + '' === ip
				)
				if (checkAMER && checkAMER.CustomerTrunkIP) {
					error =
						'You have already entered this IP for the AMER region. Please try another one.'
				}
				var checkAPAC = apacTrunkIPList.find(
					(apac) => apac.CustomerTrunkIP + '' === ip
				)
				if (checkAPAC && checkAPAC.CustomerTrunkIP) {
					error =
						'You have already entered this IP for the APAC region. Please try another one.'
				}
				var checkEMEA = emeaTrunkIPList.find(
					(emea) => emea.CustomerTrunkIP + '' === ip
				)
				if (checkEMEA && checkEMEA.CustomerTrunkIP) {
					error =
						'You have already entered this IP for the EMEA region. Please try another one.'
				}
			} else {
				error =
					'The Trunk IP entered is not in the correct format. Please try again.'
			}
		}

		return error
	}

	// Delete the IP from the AMER, APAC and EMEA trunk IP list
	const handleIPDelete = (ip: string, region: string) => {
		// Delete from list
		switch (region) {
			case 'AMER':
				setAMERTrunkIPList((ipList) =>
					ipList.filter((ipItem) => ipItem.CustomerTrunkIP + '' !== ip)
				)
				break

			case 'APAC':
				setAPACTrunkIPList((ipList) =>
					ipList.filter((ipItem) => ipItem.CustomerTrunkIP + '' !== ip)
				)
				break

			case 'EMEA':
				setEMEATrunkIPList((ipList) =>
					ipList.filter((ipItem) => ipItem.CustomerTrunkIP + '' !== ip)
				)
				break
		}
	}

	// Handle open dialog popup
	const handleOpenDialog = (region: string) => {
		var dialogTitle = ''
		var dialogDescription = ''
		switch (region) {
			case 'ALL':
				dialogTitle = 'Are you sure you wish to delete all these trunks?'
				dialogDescription = `Deleting all the trunk information will remove all numbers and trunk configuration linked to this carrier description for this customer. Proceed?`
				break

			case 'AMER':
				dialogTitle = 'Are you sure you wish to delete the AMER trunks?'
				dialogDescription = `Deleting this carrier description '${amerCarrierDescription}' will remove all numbers and trunk configuration linked to this carrier description for this customer. Proceed?`
				break

			case 'APAC':
				dialogTitle = 'Are you sure you wish to delete the APAC trunks?'
				dialogDescription = `Deleting this carrier description '${apacCarrierDescription}' will remove all numbers and trunk configuration linked to this carrier description for this customer. Proceed?`
				break

			case 'EMEA':
				dialogTitle = 'Are you sure you wish to delete the EMEA trunks?'
				dialogDescription = `Deleting this '${emeaCarrierDescription}' will remove all numbers and trunk configuration linked to this carrier description for this customer. Proceed?`
				break
		}

		// Set values
		setDialogTitle(dialogTitle)
		setDialogDescription(dialogDescription)
		setRegionToDelete(region)
		setOpenDialog(true)
	}

	// Handle close dialog popup
	const handleCloseDialog = () => {
		setOpenDialog(false)
		setRegionToDelete('')
	}

	// Disable add IP button depending on region
	const disableAddIPButton = (region: string) => {
		// Return variable
		var disableButton = false
		// Check which region
		switch (region) {
			// AMER
			case 'AMER':
				if (
					amerExists ||
					!assignAMER ||
					amerTrunkIPList.length >= 4 ||
					amerIP.length === 0 ||
					selectedAMERDataCentres.length === 0
				) {
					disableButton = true
				}
				break
			// APAC
			case 'APAC':
				if (
					apacExists ||
					!assignAPAC ||
					apacTrunkIPList.length >= 4 ||
					apacIP.length === 0 ||
					selectedAPACDataCentres.length === 0
				) {
					disableButton = true
				}
				break
			// EMEA
			case 'EMEA':
				if (
					emeaExists ||
					!assignEMEA ||
					emeaTrunkIPList.length >= 4 ||
					emeaIP.length === 0 ||
					selectedEMEADataCentres.length === 0
				) {
					disableButton = true
				}
				break
		}

		return disableButton
	}

	// Disable add trunk button
	const disableAddTrunkButton = () => {
		// Return variable
		var disableButton = false
		if (
			// If no regions have been ticked
			(!assignAMER && !assignAPAC && !assignEMEA) ||
			// If amer ticked, make sure all fields is filled in
			(assignAMER &&
				(amerCarrierDescription.length === 0 ||
					amerTrunkIPList.length === 0)) ||
			// If apac ticked, make sure all fields is filled in
			(assignAPAC &&
				(apacCarrierDescription.length === 0 ||
					apacTrunkIPList.length === 0)) ||
			// If emea ticked, make sure all fields is filled in
			(assignEMEA &&
				(emeaCarrierDescription.length === 0 ||
					emeaTrunkIPList.length === 0)) ||
			// If any errors, disable
			// AMER Errors
			(assignAMER && amerCarrierDescriptionError.length > 0) ||
			(assignAMER && amerIPError.length > 0) ||
			// APAC Errors
			(assignAPAC && apacCarrierDescriptionError.length > 0) ||
			(assignAPAC && apacIPError.length > 0) ||
			// EMEA Errors
			(assignEMEA && emeaCarrierDescriptionError.length > 0) ||
			(assignEMEA && emeaIPError.length > 0)
		) {
			disableButton = true
		}

		return disableButton
	}

	return apiCallsDone ? (
		<>
			<Box className='add-trunk-container'>
				<Box className='add-trunk-content'>
					{/* Customer ID and Name */}
					<Box className='add-trunk-customer-container'>
						{/* Image and Customer Information */}
						<Box
							className='add-trunk-customer'
							border={1}
							borderColor='border.primary'>
							{/* Image */}
							<img
								src={require('../../../assets/icons/form/trunks/trunks-icon.png')}
								alt='Trunks'
							/>
							{/* Customer ID */}
							<Box>
								<Typography component='h2'>
									{customerName}{' '}
									<Tooltip title='Navigate to the customer overview'>
										<IconButton
											onClick={() =>
												navigate(`/dashboard/customer-overview/${customerID}`)
											}>
											<OpenInNewIcon sx={{ color: 'text.primary' }} />
										</IconButton>
									</Tooltip>
								</Typography>
								<Typography component='p'>
									BizOrg: {bizOrg.length > 0 ? bizOrg : '(empty)'}
								</Typography>
							</Box>
							{/* Customer Name */}
						</Box>
					</Box>
					{/* Heading */}
					<Divider>
						<Typography component='h2' color='text.primary'>
							Trunk Details
						</Typography>
					</Divider>
					{/* Trunk Group ID */}
					<Box className='trunk-header-container'>
						<Box className='trunk-header'>
							<Typography component='p'>
								<strong>Trunk Group ID:</strong> {trunkGroupID}
							</Typography>
						</Box>
					</Box>
					{/* ALert - If any of the regions have been assigned, inform user */}
					{alertMessage.length > 0 ? (
						<Box className='add-trunk-alert'>
							<Alert severity='info'>{alertMessage}</Alert>
						</Box>
					) : null}
					{/* Trunk Regions */}
					<Box className='add-trunk-regions-container'>
						{/* AMER */}
						<Box
							className='add-trunk-region'
							bgcolor={amerExists ? 'rgba(0, 0, 0, 0.04)' : '#fff'}>
							{/* Header */}
							<Box className='add-trunk-region-header'>
								<Typography component='h3' color='text.header'>
									<PublicIcon sx={{ color: 'text.primary' }} /> AMER
								</Typography>
								{amerExists ? (
									// Delete button
									<Box className='trunk-region-delete'>
										<Tooltip title='Delete AMER Trunks'>
											<span>
												<IconButton
													disabled={deleteAMERLoading || deleteAllLoading}
													onClick={() => handleOpenDialog('AMER')}>
													<DeleteIcon />
												</IconButton>
												{deleteAMERLoading ? (
													<CircularProgress
														size={52}
														sx={{
															position: 'absolute',
															top: -6,
															left: -6,
															zIndex: 1,
															color: '#0075C9!important',
														}}
													/>
												) : null}
											</span>
										</Tooltip>
									</Box>
								) : (
									<Checkbox
										disabled={amerExists}
										checked={amerExists || assignAMER}
										icon={<CircleOutlinedIcon />}
										checkedIcon={<CheckCircleIcon />}
										value={assignAMER}
										onChange={(e) => setAssignAMER(e.target.checked)}
									/>
								)}
							</Box>
							{/* Carrier Description */}
							<Box className='add-trunk-input col'>
								<Typography component='p' color='text.header'>
									AMER Carrier Description <span>*</span>{' '}
									<Tooltip title='Enter the Carrier Name and Country, for example AllStream CAN, Colt ESP, etc. Max characters allowed is 255.'>
										<InfoIcon sx={{ color: 'text.primary' }} />
									</Tooltip>
								</Typography>
								<StyledTextBox
									disabled={amerExists || !assignAMER}
									fullWidth
									type='text'
									placeholder='Carrier Description'
									multiline
									maxRows={4}
									inputProps={{ maxLength: 255 }}
									error={amerCarrierDescriptionError.length > 0}
									helperText={
										amerCarrierDescriptionError.length > 0
											? amerCarrierDescriptionError
											: ''
									}
									value={amerCarrierDescription}
									onChange={(e) => handleCarrierDescriptionChange(e, 'AMER')}
								/>
							</Box>
							{/* Trunk IPs */}
							{!amerExists ? (
								<Box className='add-trunk-input col'>
									<Typography component='p' color='text.header'>
										AMER Trunk IP(s) <span>*</span>{' '}
										<Tooltip title='Add each trunk IP at a time. Max number of IPs allowed for each region is 4.'>
											<InfoIcon sx={{ color: 'text.primary' }} />
										</Tooltip>
									</Typography>
									<Box className='add-trunk-input-ip'>
										<StyledTextBox
											disabled={
												amerExists || !assignAMER || amerTrunkIPList.length >= 4
											}
											type='text'
											placeholder='Trunk IP'
											error={amerIPError.length > 0}
											helperText={amerIPError.length > 0 ? amerIPError : ''}
											onKeyDown={(e) => handleKeyPress(e, 'AMER')}
											value={amerIP}
											onChange={(e) => handleTrunkIPChange(e, 'AMER')}
										/>
										<StyledSelect
											disabled={
												amerExists || !assignAMER || amerTrunkIPList.length >= 4
											}
											multiple
											value={selectedAMERDataCentres}
											onChange={(e) =>
												setSelectedAMERDataCentres(e.target.value as string[])
											}
											renderValue={(selected) =>
												(selected as string[]).join(', ')
											}>
											{regionDataCentreList
												.filter((dc) => dc.RegionID === RegionOptions.AMER)
												.map((dataCentre) => (
													<MenuItem
														key={dataCentre.DataCentre?.DataCentreID}
														value={dataCentre.DataCentre?.DataCentreName}>
														<Checkbox
															checked={
																selectedAMERDataCentres.indexOf(
																	dataCentre.DataCentre?.DataCentreName ?? ''
																) > -1
															}
														/>
														{dataCentre.DataCentre?.Location}
													</MenuItem>
												))}
										</StyledSelect>
										<LoadingButton
											disabled={disableAddIPButton('AMER')}
											startIcon={<AddIcon />}
											loading={loadingAMER}
											onClick={(e) => handleAddIPButton(e, 'AMER')}>
											Add Trunk IP
										</LoadingButton>
									</Box>
								</Box>
							) : null}
							{/* List of Trunk IP Display */}
							{/* Trunk IP List display - IP and delete icon */}
							{amerTrunkIPList.length > 0 ? (
								<Box className='trunk-ip-list-container'>
									<Typography component='h4' color='text.header'>
										List of AMER Trunk IPs added
									</Typography>
									<Box className='trunk-ips'>
										{amerTrunkIPList.map((item, index) => (
											<StyledChip
												disabled={amerExists || !assignAMER}
												key={index}
												label={
													item.DataCentres !== undefined
														? `${
																item.CustomerTrunkIP
														  } - ${item.DataCentres?.join(', ')}`
														: item.CustomerTrunkIP
												}
												variant='outlined'
												onDelete={() =>
													handleIPDelete(item.CustomerTrunkIP + '', 'AMER')
												}
											/>
										))}
									</Box>
								</Box>
							) : null}
						</Box>
						{/* APAC */}
						<Box
							className='add-trunk-region'
							bgcolor={apacExists ? 'rgba(0, 0, 0, 0.04)' : '#fff'}>
							{/* Header */}
							<Box className='add-trunk-region-header'>
								<Typography component='h3' color='text.header'>
									<PublicIcon sx={{ color: 'text.primary' }} /> APAC
								</Typography>
								{apacExists ? (
									// Delete button
									<Box className='trunk-region-delete'>
										<Tooltip title='Delete APAC Trunks'>
											<span>
												<IconButton
													disabled={deleteAPACLoading || deleteAllLoading}
													onClick={() => handleOpenDialog('APAC')}>
													<DeleteIcon />
												</IconButton>
												{deleteAPACLoading ? (
													<CircularProgress
														size={52}
														sx={{
															position: 'absolute',
															top: -6,
															left: -6,
															zIndex: 1,
															color: '#0075C9!important',
														}}
													/>
												) : null}
											</span>
										</Tooltip>
									</Box>
								) : (
									<Checkbox
										disabled={apacExists}
										checked={apacExists || assignAPAC}
										icon={<CircleOutlinedIcon />}
										checkedIcon={<CheckCircleIcon />}
										value={assignAPAC}
										onChange={(e) => setAssignAPAC(e.target.checked)}
									/>
								)}
							</Box>
							{/* Carrier Description */}
							<Box className='add-trunk-input col'>
								<Typography component='p' color='text.header'>
									APAC Carrier Description <span>*</span>{' '}
									<Tooltip title='Enter the Carrier Name and Country, for example AllStream CAN, Colt ESP, etc. Max characters allowed is 255.'>
										<InfoIcon sx={{ color: 'text.primary' }} />
									</Tooltip>
								</Typography>
								<StyledTextBox
									disabled={apacExists || !assignAPAC}
									fullWidth
									type='text'
									placeholder='Carrier Description'
									multiline
									maxRows={4}
									inputProps={{ maxLength: 255 }}
									error={apacCarrierDescriptionError.length > 0}
									helperText={
										apacCarrierDescriptionError.length > 0
											? apacCarrierDescriptionError
											: ''
									}
									value={apacCarrierDescription}
									onChange={(e) => handleCarrierDescriptionChange(e, 'APAC')}
								/>
							</Box>
							{/* Trunk IPs */}
							{!apacExists ? (
								<Box className='add-trunk-input col'>
									<Typography component='p' color='text.header'>
										APAC Trunk IP(s) <span>*</span>{' '}
										<Tooltip title='Add each trunk IP at a time. Max number of IPs allowed for each region is 4.'>
											<InfoIcon sx={{ color: 'text.primary' }} />
										</Tooltip>
									</Typography>
									<Box className='add-trunk-input-ip'>
										<StyledTextBox
											disabled={
												apacExists || !assignAPAC || apacTrunkIPList.length >= 4
											}
											type='text'
											placeholder='Trunk IP'
											error={apacIPError.length > 0}
											helperText={apacIPError.length > 0 ? apacIPError : ''}
											onKeyDown={(e) => handleKeyPress(e, 'APAC')}
											value={apacIP}
											onChange={(e) => handleTrunkIPChange(e, 'APAC')}
										/>
										<StyledSelect
											disabled={
												apacExists || !assignAPAC || apacTrunkIPList.length >= 4
											}
											multiple
											value={selectedAPACDataCentres}
											onChange={(e) =>
												setSelectedAPACDataCentres(e.target.value as string[])
											}
											renderValue={(selected) =>
												(selected as string[]).join(', ')
											}>
											{regionDataCentreList
												.filter((dc) => dc.RegionID === RegionOptions.APAC)
												.map((dataCentre) => (
													<MenuItem
														key={dataCentre.DataCentre?.DataCentreID}
														value={dataCentre.DataCentre?.DataCentreName}>
														<Checkbox
															checked={
																selectedAPACDataCentres.indexOf(
																	dataCentre.DataCentre?.DataCentreName ?? ''
																) > -1
															}
														/>
														{dataCentre.DataCentre?.Location}
													</MenuItem>
												))}
										</StyledSelect>
										<LoadingButton
											disabled={disableAddIPButton('APAC')}
											startIcon={<AddIcon />}
											loading={loadingAPAC}
											onClick={(e) => handleAddIPButton(e, 'APAC')}>
											Add Trunk IP
										</LoadingButton>
									</Box>
								</Box>
							) : null}
							{/* List of Trunk IP Display */}
							{/* Trunk IP List display - IP and delete icon */}
							{apacTrunkIPList.length > 0 ? (
								<Box className='trunk-ip-list-container'>
									<Typography component='h4'>
										List of APAC Trunk IPs added
									</Typography>
									<Box className='trunk-ips'>
										{apacTrunkIPList.map((item, index) => (
											<StyledChip
												disabled={apacExists || !assignAPAC}
												key={index}
												label={
													item.DataCentres !== undefined
														? `${
																item.CustomerTrunkIP
														  } - ${item.DataCentres?.join(', ')}`
														: item.CustomerTrunkIP
												}
												variant='outlined'
												onDelete={() =>
													handleIPDelete(item.CustomerTrunkIP + '', 'APAC')
												}
											/>
										))}
									</Box>
								</Box>
							) : null}
						</Box>
						{/* EMEA */}
						<Box
							className='add-trunk-region'
							bgcolor={emeaExists ? 'rgba(0, 0, 0, 0.04)' : '#fff'}>
							{/* Header */}
							<Box className='add-trunk-region-header'>
								<Typography component='h3' color='text.header'>
									<PublicIcon sx={{ color: 'text.primary' }} /> EMEA
								</Typography>
								{emeaExists ? (
									// Delete button
									<Box className='trunk-region-delete'>
										<Tooltip title='Delete EMEA Trunks'>
											<span>
												<IconButton
													disabled={deleteEMEALoading || deleteAllLoading}
													onClick={() => handleOpenDialog('EMEA')}>
													<DeleteIcon />
												</IconButton>
												{deleteEMEALoading ? (
													<CircularProgress
														size={52}
														sx={{
															position: 'absolute',
															top: -6,
															left: -6,
															zIndex: 1,
															color: '#0075C9!important',
														}}
													/>
												) : null}
											</span>
										</Tooltip>
									</Box>
								) : (
									<Checkbox
										disabled={emeaExists}
										checked={emeaExists || assignEMEA}
										icon={<CircleOutlinedIcon />}
										checkedIcon={<CheckCircleIcon />}
										value={assignEMEA}
										onChange={(e) => setAssignEMEA(e.target.checked)}
									/>
								)}
							</Box>
							{/* Carrier Description */}
							<Box className='add-trunk-input col'>
								<Typography component='p' color='text.header'>
									EMEA Carrier Description <span>*</span>{' '}
									<Tooltip title='Enter the Carrier Name and Country, for example AllStream CAN, Colt ESP, etc. Max characters allowed is 255.'>
										<InfoIcon sx={{ color: 'text.primary' }} />
									</Tooltip>
								</Typography>
								<StyledTextBox
									disabled={emeaExists || !assignEMEA}
									fullWidth
									type='text'
									placeholder='Carrier Description'
									multiline
									maxRows={4}
									inputProps={{ maxLength: 255 }}
									error={emeaCarrierDescriptionError.length > 0}
									helperText={
										emeaCarrierDescriptionError.length > 0
											? emeaCarrierDescriptionError
											: ''
									}
									value={emeaCarrierDescription}
									onChange={(e) => handleCarrierDescriptionChange(e, 'EMEA')}
								/>
							</Box>
							{/* Trunk IPs */}
							{!emeaExists ? (
								<Box className='add-trunk-input col'>
									<Typography component='p' color='text.header'>
										EMEA Trunk IP(s) <span>*</span>{' '}
										<Tooltip title='Add each trunk IP at a time. Max number of IPs allowed for each region is 4.'>
											<InfoIcon sx={{ color: 'text.primary' }} />
										</Tooltip>
									</Typography>
									<Box className='add-trunk-input-ip'>
										<StyledTextBox
											disabled={
												emeaExists || !assignEMEA || emeaTrunkIPList.length >= 4
											}
											type='text'
											placeholder='Trunk IP'
											error={emeaIPError.length > 0}
											helperText={emeaIPError.length > 0 ? emeaIPError : ''}
											onKeyDown={(e) => handleKeyPress(e, 'EMEA')}
											value={emeaIP}
											onChange={(e) => handleTrunkIPChange(e, 'EMEA')}
										/>
										<StyledSelect
											disabled={
												emeaExists || !assignEMEA || emeaTrunkIPList.length >= 4
											}
											multiple
											value={selectedEMEADataCentres}
											onChange={(e) =>
												setSelectedEMEADataCentres(e.target.value as string[])
											}
											renderValue={(selected) =>
												(selected as string[]).join(', ')
											}>
											{regionDataCentreList
												.filter((dc) => dc.RegionID === RegionOptions.EMEA)
												.map((dataCentre) => (
													<MenuItem
														key={dataCentre.DataCentre?.DataCentreID}
														value={dataCentre.DataCentre?.DataCentreName}>
														<Checkbox
															checked={
																selectedEMEADataCentres.indexOf(
																	dataCentre.DataCentre?.DataCentreName ?? ''
																) > -1
															}
														/>
														{dataCentre.DataCentre?.Location}
													</MenuItem>
												))}
										</StyledSelect>
										<LoadingButton
											disabled={disableAddIPButton('EMEA')}
											startIcon={<AddIcon />}
											loading={loadingEMEA}
											onClick={(e) => handleAddIPButton(e, 'EMEA')}>
											Add Trunk IP
										</LoadingButton>
									</Box>
								</Box>
							) : null}
							{/* List of Trunk IP Display */}
							{/* Trunk IP List display - IP and delete icon */}
							{emeaTrunkIPList.length > 0 ? (
								<Box className='trunk-ip-list-container'>
									<Typography component='h4' color='text.header'>
										List of EMEA Trunk IPs added
									</Typography>
									<Box className='trunk-ips'>
										{emeaTrunkIPList.map((item, index) => (
											<StyledChip
												disabled={emeaExists || !assignEMEA}
												key={index}
												label={
													item.DataCentres !== undefined
														? `${
																item.CustomerTrunkIP
														  } - ${item.DataCentres?.join(', ')}`
														: item.CustomerTrunkIP
												}
												variant='outlined'
												onDelete={() =>
													handleIPDelete(item.CustomerTrunkIP + '', 'EMEA')
												}
											/>
										))}
									</Box>
								</Box>
							) : null}
						</Box>
					</Box>
					{/* Button group */}
					<Box className='add-trunk-buttons'>
						{/* Add */}
						<LoadingButton
							disabled={disableAddTrunkButton()}
							className='add'
							variant='contained'
							loading={addLoading}
							onClick={addTrunks}>
							Add Trunk(s)
						</LoadingButton>
						{/* Delete */}
						<LoadingButton
							disabled={!amerExists && !apacExists && !emeaExists}
							loading={deleteAllLoading}
							className='delete'
							variant='contained'
							onClick={() => handleOpenDialog('ALL')}>
							Delete All Trunk(s)
						</LoadingButton>
					</Box>
				</Box>
				{/* Dialog */}
				<Dialog
					open={openDialog}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'>
					<DialogTitle id='alert-dialog-title'>{dialogTitle}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							{dialogDescription}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseDialog} sx={{ color: '#db3d47' }}>
							Cancel
						</Button>
						<Button onClick={deleteTrunks} autoFocus>
							Delete Trunks
						</Button>
					</DialogActions>
				</Dialog>
			</Box>
		</>
	) : (
		<LoadingCard
			loadingCardTitle={`Loading ${customerID} Trunk Information`}
			loadingCardDescription='Please wait as we load your trunk information'
		/>
	)
}

export default AddTrunk
