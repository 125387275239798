import {
	Alert,
	Box,
	Button,
	Divider,
	IconButton,
	MenuItem,
	Modal,
	TextField,
	Tooltip,
	Typography,
	styled,
} from '@mui/material'
import { StyledSelect } from '../../../../../shared/styledComponents/StyledSelect/StyledSelect'
import { useEffect, useRef, useState } from 'react'
import {
	CarrierCountryMap,
	Country,
	DDIType,
	Service,
	ServiceType,
	TrunkGroup,
} from '../../../../../../utils/interfaces/DBModels'
import {
	DDIStatuses,
	DDITypes,
	ServiceCategory,
	ServiceTypes,
} from '../../../../../../utils/enums/enums'
import './AddTNs.scss'
import ClearIcon from '@mui/icons-material/Clear'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import AttachmentIcon from '@mui/icons-material/Attachment'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { StyledDataGrid } from '../../../../../shared/datagrid/StyledDataGrid'
import {
	getUTCDateTimeNow,
	showErrorToast,
	showSuccessToast,
	toAlphaString,
	toBeta,
} from '../../../../../../utils/helperFunctions/helperFunctions'
import {
	AddDDIRangeModel,
	BetaObject,
} from '../../../../../../utils/interfaces/APIModels'
import { usePostBetaObjectAddDDIRangeMutation } from '../../../../../../services/proxyAPIData'
import { LoadingButton } from '@mui/lab'
import AddNewRange from './AddNewRange/AddNewRange'
import { TrunkGroupIDRegionMapping } from '../../../../../../utils/interfaces/ComponentModels'

const AddTNs = ({
	customerID,
	orderSKUID,
	countryDropdownList,
	serviceTypeDropdownList,
	customerServiceList,
	handleCloseMenuAndModal,
	carrierCountryMapList,
	trunkGroupList,
}: {
	customerID: string
	orderSKUID: number
	countryDropdownList: Country[]
	serviceTypeDropdownList: ServiceType[]
	customerServiceList: Service[]
	handleCloseMenuAndModal: any
	carrierCountryMapList: CarrierCountryMap[]
	trunkGroupList: TrunkGroup[]
}) => {
	//Lists
	const [applicationServiceTypeList, setApplicationServiceTypeList] = useState(
		[] as ServiceType[]
	)
	const [connectivityServiceTypeList, setConnectivityServiceTypeList] =
		useState([] as ServiceType[])
	const [ingressServiceList, setIngressServiceList] = useState([] as Service[])
	const [egressServiceList, setEgressServiceList] = useState([] as Service[])
	const trunkGroupRegionMappingList = useRef<TrunkGroupIDRegionMapping[]>([])

	//constants
	const maxCSVFileSize = 50000000
	const countryID = useRef(0)
	const [isTrunk, setIsTrunk] = useState(false)
	const [isSharedCarrier, setIsSharedCarrier] = useState(false)
	const [dialingCode, setDialingCode] = useState<string | undefined>(undefined)
	const [selectedFileName, setSelectedFileName] = useState<string>('')
	const [isCountryRequired, setIsCountryRequired] = useState(false)
	const [addRangeButtonErrorMessage, setAddRangeButtonErrorMessage] =
		useState('')
	const [file, setFile] = useState(null as any)
	const [ingressServiceTypeID, setIngressServiceTypeID] = useState(
		'Please select a Service Type'
	)
	const [ingressServiceID, setIngressServiceID] = useState(
		'Please select a Service'
	)
	const [egressServiceTypeID, setEgressServiceTypeID] = useState(
		'Please select a Service Type'
	)
	const [egressServiceID, setEgressServiceID] = useState(
		'Please select a Service'
	)
	const [btnLoading, setBtnLoading] = useState(false)

	//csv file
	const fileInputRef = useRef<HTMLInputElement>(null)
	const [isCSVLoading, setIsCSVLoading] = useState(false)

	//Errors
	const [csvErrorMessage, setCSVErrorMessage] = useState('')
	const [isCSVValidatedSuccessfully, setIsCSVValidatedSuccessfully] =
		useState(false)
	const [ingressServiceError, setIngressServiceError] = useState(false)
	const [egressServiceTypeError, setEgressServiceTypeError] = useState(false)
	const [egressServiceError, setEgressServiceError] = useState(false)
	const [bodyHeading, setBodyHeading] = useState([] as any)
	const [invalidRangesToDisplay, setInvalidRangesToDisplay] = useState(
		[] as any
	)
	const [isIngressServiceTypeError, setIsIngressServiceTypeError] =
		useState(false)
	const [sharedCarrierID, setSharedCarrierID] = useState('')
	const [apiError, setApiError] = useState('')
	const [isCSVDDIRangeInsert, setIsCSVDDIRangeInsert] = useState(false)
	const [isManualDDIRangeInsert, setIsManualDDIRangeInsert] = useState(false)
	const [trunksConfiguredInCountryErr, setTrunksConfiguredInCountryErr] =
		useState('')
	const [showCountryErr, setShowCountryErr] = useState(false)

	//add new range
	const [newRangesList, setNewRangesList] = useState([] as any)
	const [addDDIRange] = usePostBetaObjectAddDDIRangeMutation()

	//ddi range table
	const [rows, setRows] = useState([] as any)

	//modal
	const [isOpen, setIsOpen] = useState(false)

	//Custom styling
	const InfoAlert = styled(Alert)({
		'& .MuiAlert-icon': {
			color: '#0075c9',
		},
		backgroundColor: 'white',
		color: 'grey',
		fontSize: '14px',
	})

	const customBoxStyle = {
		display: 'flex',
		flexDirection: 'row',
		columnGap: '5px',
		alignItems: 'center',
	}

	useEffect(() => {
		if (serviceTypeDropdownList) {
			var applicationServices = serviceTypeDropdownList.filter(
				(x) => x.ServiceCategoryID === ServiceCategory.Application
			)

			setApplicationServiceTypeList(applicationServices)

			var connectivityServices = serviceTypeDropdownList.filter(
				(x) => x.ServiceCategoryID === ServiceCategory.Connectivity
			)
			setConnectivityServiceTypeList(connectivityServices)
			//reset to default
			countryID.current = 0
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serviceTypeDropdownList])

	//CSV functions
	const handleButtonClick = () => {
		if (dialingCode === undefined) {
			setIsCountryRequired(true)
			setCSVErrorMessage('Please select a country')
			return
		} else {
			setIsCountryRequired(false)
			setCSVErrorMessage('')
		}

		if (fileInputRef.current) {
			fileInputRef.current.click()
		}
	}

	const handleClearClick = () => {
		setCSVErrorMessage('')
		clearFile()
		setIsCSVValidatedSuccessfully(false)
		setIsCSVLoading(false)
		setIsCSVDDIRangeInsert(false)
		setSelectedFileName('')
		setNewRangesList([])
		setIsCountryRequired(false)
		setAddRangeButtonErrorMessage('')
	}

	const clearFile = () => {
		if (fileInputRef.current) {
			fileInputRef.current.value = ''
		}
		setFile(null)
		setRows([] as any)
		setBodyHeading('')
		setInvalidRangesToDisplay([])
	}

	const handleFileChange = (event: any) => {
		setCSVErrorMessage('')
		setIsCSVValidatedSuccessfully(false)
		setIsManualDDIRangeInsert(false)
		//check if there's a file
		const file = event.target.files[0]

		if (file) {
			setSelectedFileName(file.name)
			setFile(file)
			setIsCSVDDIRangeInsert(true)

			//check file type
			var isCSVType = (file.name + ''.toLowerCase()).endsWith('.csv')
			if (isCSVType) {
				//check file size
				if (file.size > maxCSVFileSize) {
					setCSVErrorMessage(
						'The selected file is too big. Max file size is 50 MB.'
					)
				}

				validateCSVFile(file)
			} else {
				setCSVErrorMessage('Invalid file type. Please select a CSV file.')
			}
		} else {
			handleClearClick()
			return
		}
	}

	const validateCSVFile = async (file: any) => {
		setCSVErrorMessage('')
		setIsCSVLoading(true)

		try {
			const formData = new FormData()
			formData.append('file', file)
			formData.append('CountryID', countryID.current + '')

			var hasError = false
			await fetch(
				process.env.REACT_APP_DDI_FUNCTION_API_BASE_URL + 'ValidateDDICSV',
				{
					method: 'POST',
					headers: new Headers({
						'x-functions-key': process.env.REACT_APP_DDI_FUNCTION_KEY + '',
					}),
					body: formData,
				}
			)
				.then((response) => {
					if (!response.ok) {
						hasError = true
					}
					return response.json()
				})
				.then((result) => {
					setIsCSVValidatedSuccessfully(true)

					if (result.errorMessage || hasError) {
						setCSVErrorMessage(`Error: ${result.errorMessage}`)

						setIsCSVValidatedSuccessfully(false)
					} else {
						for (var i = 0; i < result.length; i++) {
							result[i].ID = i + 1
						}

						//set the rows for table
						setRows(result)
						setNewRangesList(result)
						setIsCSVValidatedSuccessfully(true)
					}
				})
		} catch (error) {
			setIsCSVValidatedSuccessfully(false)
			setCSVErrorMessage(
				'An error occurred when trying to validate your CSV file.'
			)
		}

		setIsCSVLoading(false)
	}

	const importCSVFile = async () => {
		setCSVErrorMessage('')
		setBtnLoading(true)
		try {
			const formData = new FormData()
			formData.append('file', file)
			formData.append('customerID', customerID)
			formData.append('countryID', countryID.current + '')
			formData.append('OrderSKUID', orderSKUID + '')
			formData.append('ddiTypeID', DDITypes.PartnerManaged + '')
			formData.append('ddiStatusID', DDIStatuses.Inactive + '')
			formData.append('ServiceTypeInID', Number(ingressServiceTypeID) + '')
			formData.append(
				'ServiceInID',
				isSharedCarrier ? sharedCarrierID : ingressServiceID
			)
			formData.append('ServiceTypeOutID', Number(egressServiceTypeID) + '')
			formData.append('ServiceOutID', egressServiceID)
			formData.append('DateAdded', getUTCDateTimeNow() + '')
			formData.append('IsActive', false + '')
			formData.append('IsAssigned', false + '')

			var hasError = false
			await fetch(
				process.env.REACT_APP_DDI_FUNCTION_API_BASE_URL + 'ImportDDICSV',
				{
					method: 'POST',
					headers: new Headers({
						'x-functions-key': process.env.REACT_APP_DDI_FUNCTION_KEY + '',
					}),
					body: formData,
				}
			)
				.then((response) => {
					if (!response.ok) {
						hasError = true
						return response.json()
					}
				})
				.then(async (result) => {
					if ((result && result.errorMessage) || hasError) {
						//add the invalid ranges to the invalidranges[]
						if (result.errorInformation[0].invalidRanges) {
							var bodyList = result.errorInformation
							var bodyHeading = bodyList[0].errorMessage
							setBodyHeading(bodyHeading)

							//then loop through the list and add the ddi range start and end to the []
							var invalidRangesList: any[] =
								result.errorInformation[0].invalidRanges

							var invalidRangesDisplayList = [] as any

							//loop through invalid ranges list
							for (var x = 0; x < invalidRangesList.length; x++) {
								var invalidRange = {
									RangeStart: invalidRangesList[x].DDIRangeStart.replace(
										'+',
										''
									),
									RangeEnd: invalidRangesList[x].DDIRangeEnd.replace('+', ''),
								}

								invalidRangesDisplayList.push(invalidRange)
							}

							setInvalidRangesToDisplay(invalidRangesDisplayList)
							setCSVErrorMessage(`Error: ${result.errorMessage}`)
						}
					} else {
						showSuccessToast('Numbers were imported successfully')
						setInvalidRangesToDisplay([])
						handleCloseMenuAndModal()
					}
				})
		} catch (error) {
			setCSVErrorMessage(
				'An error occurred when trying to import your CSV file.'
			)
		}
		setBtnLoading(false)
	}

	//check if ingress service selected
	const checkTrunkGroupRegions = async () => {
		if (ingressServiceID) {
			for (var i = 0; i < trunkGroupList.length; i++) {
				var regionIdsList = new Set<number>()
				var customerTrunkGroupList = trunkGroupList[i].CustomerTrunkGroupIPList

				if (customerTrunkGroupList) {
					customerTrunkGroupList.map((y) =>
						y.CustomerTrunkGroupRegionMapList?.map((x) =>
							regionIdsList.add(Number(x.RegionID))
						)
					)
				}

				trunkGroupRegionMappingList.current.push({
					TrunkGroupID: trunkGroupList[i].TrunkGroupID,
					RegionList: regionIdsList,
				})
			}
		}
	}

	//function to check if selected ingress service exists in country selected
	const doesTrunkGroupExistInCountrySelected = (
		regionId: number | undefined
	) => {
		//loop through the trunkGroupRegionMappingList
		for (var i = 0; i < trunkGroupRegionMappingList.current.length; i++) {
			if (
				trunkGroupRegionMappingList.current[i].TrunkGroupID === ingressServiceID
			) {
				var isTrunkIPConfiguredInRegion = trunkGroupRegionMappingList.current[
					i
				].RegionList?.has(Number(regionId))

				if (isTrunkIPConfiguredInRegion) {
					//set err to false
					setTrunksConfiguredInCountryErr('')
					setShowCountryErr(false)
				} else {
					//set err to true
					setTrunksConfiguredInCountryErr(
						'Ingress Service is not configured to support country selected. Please select another country.'
					)
					setShowCountryErr(true)
				}
			}
		}
	}

	//Form handle functions
	const handleCountryDropdownChange = async (e: { target: any }) => {
		handleClearClick()
		var countrySelected = e.target.value

		var matchingCountry = countryDropdownList.find(
			(x) => x.CountryID === countrySelected
		)

		if (matchingCountry) {
			countryID.current = countrySelected
			setDialingCode(matchingCountry?.CountryDiallingCode)

			doesTrunkGroupExistInCountrySelected(matchingCountry?.RegionID)

			var findSharedCarrierID = carrierCountryMapList.find(
				(x) => x.CountryID === countrySelected && x.Priority === 1
			)?.SharedCarrierID

			if (findSharedCarrierID) {
				setSharedCarrierID(findSharedCarrierID)
			}
		}
	}

	const handleIngressServiceTypeChange = async (e: { target: any }) => {
		setIsSharedCarrier(false)
		setTrunksConfiguredInCountryErr('')
		setShowCountryErr(false)
		//reset the country selected
		countryID.current = 0
		const ingressServiceTypeIDSelected = e.target.value

		if (ingressServiceTypeIDSelected === ServiceTypes.BYOC) {
			//set flag to true
			setIsTrunk(true)
		}

		if (ingressServiceTypeIDSelected === ServiceTypes.SharedCarrier) {
			setIsSharedCarrier(true)
		}

		if (ingressServiceTypeIDSelected !== undefined) {
			setIngressServiceTypeID(ingressServiceTypeIDSelected)
			setIsIngressServiceTypeError(false)
			setIngressServiceID('Please select a Service')

			// filter customer services for type selected
			var ingressServices = customerServiceList.filter(
				(x) => x.ServiceTypeID === Number(ingressServiceTypeIDSelected)
			)
			setIngressServiceList(ingressServices)
		}
	}

	const handleIngressServiceChange = async (e: { target: any }) => {
		const ingressServiceIDSelected = e.target.value

		//set to false
		setTrunksConfiguredInCountryErr('')
		setShowCountryErr(false)
		//reset if trunk group selected changes
		countryID.current = 0

		if (ingressServiceIDSelected !== undefined) {
			setIngressServiceID(ingressServiceIDSelected)
			setIngressServiceError(false)
		}

		if (isTrunk) {
			//reset the array so it doesn't keep recreating new objects
			trunkGroupRegionMappingList.current = []
			checkTrunkGroupRegions()
		}
	}

	const handleEgressServiceTypeChange = async (e: { target: any }) => {
		const egressServiceTypeIDSelected = e.target.value

		if (egressServiceTypeIDSelected !== undefined) {
			setEgressServiceTypeID(egressServiceTypeIDSelected)
			setEgressServiceTypeError(false)

			// filter customer services for type selected
			var egressServices = customerServiceList.filter(
				(x) => x.ServiceTypeID === Number(egressServiceTypeIDSelected)
			)
			setEgressServiceList(egressServices)
		}
	}

	const handleEgressServiceChange = async (e: { target: any }) => {
		const egressServiceIDSelected = e.target.value

		if (egressServiceIDSelected !== undefined) {
			setEgressServiceID(egressServiceIDSelected)
			setEgressServiceError(false)
		}
	}

	//Modal functions
	const handleOpenModal = () => {
		if (dialingCode === undefined) {
			setIsCountryRequired(true)
			setAddRangeButtonErrorMessage('Please select a country')
			return
		} else {
			setIsCountryRequired(false)
			setAddRangeButtonErrorMessage('')
		}
		setIsOpen(true)
	}

	const handleClose = () => {
		setIsOpen(false)
	}

	//set the row data
	const setRangeDataForRow = (newRangesList: any) => {
		setRows(newRangesList)
		if (newRangesList.length > 0 && !isCSVValidatedSuccessfully) {
			setIsCSVDDIRangeInsert(false)
			setIsManualDDIRangeInsert(true)
		}
	}

	//deleteRange onclick function
	const deleteRange = (id: number) => {
		const indexToDelete = newRangesList.findIndex(
			(range: { ID: number }) => range.ID === id
		)
		if (indexToDelete !== -1) {
			const copyOfLatestRangesAdded = [...newRangesList]
			copyOfLatestRangesAdded.splice(indexToDelete, 1)
			setRangeDataForRow(copyOfLatestRangesAdded)
			setNewRangesList(copyOfLatestRangesAdded)
			setRows(copyOfLatestRangesAdded)
			setApiError('')

			//check if the range they want to delete is inside the invalidranges list , if yes remove it
			const copyofLatestINvalidRanges = [...invalidRangesToDisplay]
			copyofLatestINvalidRanges.splice(indexToDelete, 1)
			setInvalidRangesToDisplay(copyofLatestINvalidRanges)

			if (copyOfLatestRangesAdded.length === 0) {
				setIsManualDDIRangeInsert(false)
				if (isCSVDDIRangeInsert) {
					handleClearClick()
				}
			}
		}
	}

	//POST: Add DDI Range
	const AddDDIRangeToDB = async () => {
		setBtnLoading(true)

		// ingress and egress services are not the same
		if (ingressServiceTypeID !== egressServiceTypeID) {
			var addDDIRangeRequest: AddDDIRangeModel[] = []
			for (var i = 0; i < newRangesList.length; i++) {
				addDDIRangeRequest.push({
					DDIRangeStart: `${newRangesList[i].ddiRangeStart}`,
					DDIRangeEnd: `${newRangesList[i].ddiRangeEnd}`,
					CustomerID: customerID,
					CountryID: countryID.current,
					OrderSKUID: orderSKUID,
					DDITypeID: DDITypes.PartnerManaged,
					DDIStatusID: DDIStatuses.Inactive,
					ServiceTypeInID: Number(ingressServiceTypeID),
					ServiceInID: isSharedCarrier ? sharedCarrierID : ingressServiceID,
					ServiceTypeOutID: Number(egressServiceTypeID),
					ServiceOutID: egressServiceID,
					DateAdded: getUTCDateTimeNow(),
					IsActive: false,
					IsAssigned: false,
					enableServiceRouting: true,
				})
			}
			var betaDataObj = await toBeta(addDDIRangeRequest)

			var betaObj: BetaObject = {
				Content: betaDataObj,
			}

			await addDDIRange(betaObj)
				.unwrap()
				.then(async () => {
					handleCloseMenuAndModal()
					showSuccessToast(`TN(s) were added successfully`)
				})
				.catch(async (err) => {
					if (err) {
						var errorResponse: any = await toAlphaString(err.data.Content)

						if (errorResponse) {
							//check if the error response is a json object
							try {
								var cleanErrResponse = JSON.parse(errorResponse)

								if (cleanErrResponse) {
									var bodyList = cleanErrResponse.errorInformation

									for (var i = 0; i < bodyList.length; i++) {
										var bodyHeading = bodyList[i].errorMessage
										setBodyHeading(bodyHeading)

										var invalidRangesList = bodyList[i].invalidRanges

										var invalidRangesDisplayList = [] as any

										//loop through invalid ranges list
										for (var x = 0; x < invalidRangesList.length; x++) {
											//loop through the invalid ranges list
											var invalidRange = {
												RangeStart: invalidRangesList[x].DDIRangeStart.replace(
													dialingCode,
													''
												),
												RangeEnd: invalidRangesList[x].DDIRangeEnd.replace(
													dialingCode,
													''
												),
											}

											invalidRangesDisplayList.push(invalidRange)
										}

										setInvalidRangesToDisplay(invalidRangesDisplayList)
									}
								}
							} catch (error) {
								setApiError(errorResponse)
								return
							}
						} else {
							setApiError(errorResponse)
							return
						}
					}
				})
		} else {
			showErrorToast('Ingress and Egress Service cannot be identical')
			return
		}

		setBtnLoading(false)
	}

	//Disable confirm ranges btn
	const disableConfirmRanges = () => {
		var disableConfirmBtn = false
		if (
			ingressServiceTypeID === 'Please select a Service Type' ||
			(ingressServiceID === 'Please select a Service' && !isSharedCarrier) ||
			egressServiceTypeID === 'Please select a Service Type' ||
			egressServiceID === 'Please select a Service' ||
			showCountryErr ||
			countryID.current === 0 ||
			csvErrorMessage.length > 0 ||
			invalidRangesToDisplay.length > 0 ||
			newRangesList.length < 1
		) {
			disableConfirmBtn = true
		}

		return disableConfirmBtn
	}

	return (
		<>
			<Box className='tn-form-container'>
				{/* Heading */}
				<Box className='tn-form-header'>
					<img
						src={require('../../../../../../assets/icons/addTNs/AddTNsIcon.png')}
						alt='Add Numbers Icon'
					/>
					<Typography
						id='order-form-header'
						component='h1'
						style={{ fontSize: '25px' }}>
						Add Number Ranges
						<Typography component='h4' style={{ fontWeight: '600' }}>
							You can add new Number ranges here:{' '}
						</Typography>
					</Typography>
				</Box>

				<Divider />

				<Box className='tn-heading'>
					<Typography style={{ fontWeight: '600' }}>
						Number Service Details:{' '}
					</Typography>
					<InfoAlert severity='info'>
						Please fill in the necessary Number range details below.
					</InfoAlert>{' '}
				</Box>

				{/* TN Details*/}
				<Box className='dropdown-box'>
					<Box sx={{ padding: '15px' }}>
						<Typography>Ingress Service Type:</Typography>
						<Box className='dropdown'>
							<StyledSelect
								fullWidth
								value={ingressServiceTypeID}
								error={isIngressServiceTypeError}
								type='text'
								onChange={handleIngressServiceTypeChange}>
								<MenuItem disabled value={'Please select a Service Type'}>
									Please select a Service Type
								</MenuItem>
								{connectivityServiceTypeList?.map((item, index) => (
									<MenuItem key={index} value={item.ServiceTypeID}>
										{item.ServiceTypeName}
									</MenuItem>
								))}
							</StyledSelect>
						</Box>
					</Box>

					<Box sx={{ padding: '15px' }}>
						<Typography>Egress Service Type:</Typography>
						<Box className='dropdown'>
							<StyledSelect
								fullWidth
								value={egressServiceTypeID}
								error={egressServiceTypeError}
								type='text'
								onChange={handleEgressServiceTypeChange}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: '200px',
											overflow: 'auto',
										},
									},
								}}>
								<MenuItem disabled value={'Please select a Service Type'}>
									Please select a Service Type
								</MenuItem>
								{applicationServiceTypeList?.map((item, index) => (
									<MenuItem key={index} value={item.ServiceTypeID}>
										{item.ServiceTypeName}
									</MenuItem>
								))}
							</StyledSelect>
						</Box>
					</Box>

					<Box sx={{ padding: '15px' }}>
						<Typography>Ingress Service:</Typography>
						<Box className='dropdown'>
							<StyledSelect
								fullWidth
								value={ingressServiceID}
								error={ingressServiceError}
								type='text'
								disabled={isSharedCarrier}
								onChange={handleIngressServiceChange}>
								<MenuItem disabled value={'Please select a Service'}>
									Please select a Service
								</MenuItem>
								{ingressServiceList?.map((item, index) => (
									<MenuItem key={index} value={item.ServiceID}>
										{item.ServiceID}
									</MenuItem>
								))}
							</StyledSelect>
						</Box>
					</Box>

					<Box sx={{ padding: '15px' }}>
						<Typography>Egress Service:</Typography>
						<Box className='dropdown'>
							<StyledSelect
								fullWidth
								value={egressServiceID}
								error={egressServiceError}
								type='text'
								onChange={handleEgressServiceChange}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: '200px',
											overflow: 'auto',
										},
									},
								}}>
								<MenuItem disabled value={'Please select a Service'}>
									Please select a Service
								</MenuItem>
								{egressServiceList?.map((item, index) => (
									<MenuItem key={index} value={item.ServiceID}>
										{item.ServiceID}
									</MenuItem>
								))}
							</StyledSelect>
						</Box>
					</Box>

					<Box sx={{ padding: '15px' }}>
						<Typography style={{ fontWeight: '600' }}>
							Number Details:{' '}
						</Typography>
					</Box>

					<Box sx={{ padding: '15px' }}></Box>

					<Box sx={{ padding: '15px' }}>
						<Typography>Number Type:</Typography>
						<Box className='dropdown'>
							<StyledSelect
								fullWidth
								value={DDITypes.PartnerManaged}
								type='text'
								disabled>
								<MenuItem disabled value={DDITypes.PartnerManaged}>
									Partner Managed
								</MenuItem>
							</StyledSelect>
						</Box>
					</Box>

					<Box sx={{ padding: '15px' }}>
						<Typography>Country: </Typography>
						<Box className='dropdown'>
							<StyledSelect
								fullWidth
								value={countryID.current}
								error={isCountryRequired}
								disabled={
									ingressServiceID === 'Please select a Service' &&
									Number(ingressServiceTypeID) !==
										Number(ServiceTypes.SharedCarrier)
										? true
										: false
								}
								type='text'
								onChange={handleCountryDropdownChange}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: '200px',
										},
									},
								}}>
								<MenuItem disabled value={0}>
									Choose a country
								</MenuItem>
								{countryDropdownList.map((item, index) => {
									return (
										<MenuItem key={index} value={item.CountryID}>
											{item.CountryName}
										</MenuItem>
									)
								})}
							</StyledSelect>
						</Box>
						{showCountryErr && (
							<>
								<Typography
									component='p'
									style={{ color: 'red', fontSize: '13px' }}>
									{trunksConfiguredInCountryErr}
								</Typography>
							</>
						)}
					</Box>
				</Box>

				{/* insert disclaimer here so they dont incl the dialling code */}
				<InfoAlert severity='info'>
					Please don't include the dialing code when uploading ranges through a
					csv file or manually.
				</InfoAlert>

				{/* CSV upload */}
				<Box className='tn-heading' sx={{ padding: '15px' }}>
					<Typography>
						CSV Upload:{' '}
						<a
							className='csv-sample-link'
							href={`${process.env.REACT_APP_DDI_CSV_TEMPLATE_URL}`}
							download='DDI_CSV_import_sample.csv'>
							CSV Template
						</a>
					</Typography>

					<Box></Box>

					<input
						accept='.csv'
						ref={fileInputRef}
						style={{ display: 'none' }}
						type='file'
						onChange={handleFileChange}
						disabled={isCSVLoading || isCSVValidatedSuccessfully}
					/>
					<TextField
						value={selectedFileName || ''}
						placeholder='Select file'
						InputProps={{
							startAdornment: (
								<Tooltip
									title={
										!isManualDDIRangeInsert
											? 'Select and upload a CSV file.'
											: 'To upload a CSV file, please delete any manually added Number ranges first.'
									}>
									<span style={{ display: 'inline-block' }}>
										<IconButton
											disabled={isManualDDIRangeInsert}
											onClick={handleButtonClick}>
											<AttachmentIcon />
										</IconButton>
									</span>
								</Tooltip>
							),
							endAdornment: (
								<Tooltip
									title={
										!isManualDDIRangeInsert
											? 'Delete the Number ranges that were uploaded from the CSV file.'
											: 'You cannot delete manually added Number ranges here. Please use the table below to delete them.'
									}>
									<span style={{ display: 'inline-block' }}>
										<IconButton
											disabled={isManualDDIRangeInsert}
											onClick={handleClearClick}>
											<ClearIcon />
										</IconButton>
									</span>
								</Tooltip>
							),
						}}
						fullWidth
						disabled
						autoFocus
					/>
					<Box></Box>

					{csvErrorMessage && <p style={{ color: 'red' }}>{csvErrorMessage}</p>}
				</Box>

				{/* Ranges Table */}
				<Box sx={{ padding: '15px' }}>
					<Tooltip
						title={
							isCSVDDIRangeInsert
								? 'To manually add Number ranges, please remove the CSV file import first.'
								: 'Add New Range'
						}>
						<span style={{ display: 'inline-block' }}>
							<Button
								className='add-new-range-btn'
								disabled={isCSVDDIRangeInsert}
								onClick={handleOpenModal}>
								+ Add new range
							</Button>
							{addRangeButtonErrorMessage.length > 0 && (
								<p style={{ color: 'red' }}>{addRangeButtonErrorMessage}</p>
							)}
						</span>
					</Tooltip>
					<StyledDataGrid
						sx={{
							'& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
								display: 'none',
							},
						}}
						initialState={{
							columns: {
								columnVisibilityModel: {
									ID: false,
								},
							},
						}}
						columns={[
							{
								field: 'ID',
								headerName: 'ID',
								width: 100,
								hideable: false,
								flex: 2,
							},
							{
								field: 'ddiRangeStart',
								headerName: 'Number Start',
								width: 100,
								hideable: false,
								flex: 2,
								renderCell: (params: GridRenderCellParams<any>) => {
									const isInvalidRangeStart = (rangeStart: any) => {
										return invalidRangesToDisplay.some(
											(range: any) =>
												range.RangeStart ===
												dialingCode?.replace('+', '') + rangeStart
										)
									}

									const isInvalid = isInvalidRangeStart(params.value)

									const className = isInvalid ? 'invalid' : 'valid'
									return <span className={className}>{params.value}</span>
								},
							},
							{
								field: 'ddiRangeEnd',
								headerName: 'Number End',
								width: 100,
								hideable: false,
								flex: 2,
								renderCell: (params: GridRenderCellParams<any>) => {
									const isInvalidRangeEnd = (rangeEnd: any) => {
										return invalidRangesToDisplay.some(
											(range: any) =>
												range.RangeEnd ===
												dialingCode?.replace('+', '') + rangeEnd
										)
									}

									const isInvalid = isInvalidRangeEnd(params.value)

									const className = isInvalid ? 'invalid' : 'valid'
									return <span className={className}>{params.value}</span>
								},
							},
							{
								field: 'DeleteRange',
								headerName: '',
								width: 100,
								hideable: false,
								align: 'center',
								flex: 1,
								renderCell: (params: GridRenderCellParams<any>) => (
									<>
										{isManualDDIRangeInsert && (
											<Box sx={customBoxStyle}>
												<Tooltip title='Delete Number range'>
													<IconButton
														id='basic-button'
														onClick={(e: any) => {
															deleteRange(params.row.ID)
														}}>
														<HighlightOffIcon style={{ color: 'red' }} />
													</IconButton>
												</Tooltip>
											</Box>
										)}
									</>
								),
							},
						]}
						rows={rows}
						getRowId={(row) => row.ID}
						autoPageSize={true}
						autoHeight={true}
						hideFooter
					/>

					{/*error for invalid ranges*/}
					{invalidRangesToDisplay.length > 0 && (
						<>
							<Box sx={{ padding: '15px' }}>
								<Typography component='p' sx={{ fontSize: '14px' }}>
									<Typography component='p' sx={{ fontSize: '12px' }}>
										<span style={{ color: 'red' }}>ERROR:</span> Please note:
										Invalid ranges are marked in red
									</Typography>
								</Typography>
								<Box sx={{ paddingTop: '5px' }}>
									<Typography component='p' sx={{ fontSize: '12px' }}>
										{bodyHeading}{' '}
									</Typography>
								</Box>
							</Box>
						</>
					)}

					{/* API errors */}
					{apiError.length > 0 && (
						<>
							<Box sx={{ padding: '15px' }}>
								<Typography component='p' sx={{ fontSize: '12px' }}>
									<span style={{ color: 'red' }}>ERROR:</span> {apiError}
								</Typography>
							</Box>
						</>
					)}
				</Box>

				{/* Modal when adding a new range */}
				<Modal
					open={isOpen}
					onClose={handleClose}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'>
					<AddNewRange
						newRangesList={newRangesList}
						setNewRangesList={setNewRangesList}
						handleClose={handleClose}
						setRangeDataForRow={setRangeDataForRow}
						CountryDiallingCodeLength={Number(
							dialingCode?.replace('+', '').length
						)}
						countryDiallingCode={dialingCode}
					/>
				</Modal>

				<Box className='add-tn-button' sx={{ padding: '15px', margin: 'auto' }}>
					<LoadingButton
						onClick={() => {
							if (isCSVValidatedSuccessfully) {
								importCSVFile()
							} else {
								AddDDIRangeToDB()
							}
						}}
						disabled={disableConfirmRanges()}
						loading={btnLoading}>
						Confirm Range(s)
					</LoadingButton>
				</Box>
			</Box>
		</>
	)
}

export default AddTNs
