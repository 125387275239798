import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
	BetaAPIMutation,
	BetaObject,
	BetaSREMutation,
} from '../utils/interfaces/APIModels'
import { RootState } from '../store/store'
import { CustomerPartners } from '../utils/enums/enums'

const baseURL = process.env.REACT_APP_PROXY_FUNCTION_BASE_URL

export const proxyAPIData = createApi({
	reducerPath: 'proxyAPIData',
	baseQuery: fetchBaseQuery({
		prepareHeaders: (headers, { getState }) => {
			const accessToken = (getState() as RootState).RootReducer
				.accessTokenReducer.value
			headers.set(
				'x-functions-key',
				process.env.REACT_APP_API_PROXY_FUNCTION_KEY + ''
			)
			headers.append('authorization', accessToken)
			headers.set('PartnerID', CustomerPartners.Lumen + '')
			return headers
		},
		baseUrl: baseURL,
	}),
	tagTypes: ['Data', 'Permissions', 'User'],
	refetchOnReconnect: true,

	endpoints: (build) => ({
		PostBetaObjectWithRefetch: build.mutation<
			BetaObject | undefined | null,
			BetaAPIMutation
		>({
			query: (apiMutation) => ({
				url: apiMutation.QueryParam,
				method: 'POST',
				body: apiMutation.BetaObject,
			}),
			invalidatesTags: ['Data'],
		}),

		PostBetaObjectWithoutRefetch: build.mutation<
			BetaObject | undefined | null,
			BetaAPIMutation
		>({
			query: (apiMutation) => ({
				url: apiMutation.QueryParam,
				method: 'POST',
				body: apiMutation.BetaObject,
			}),
		}),

		PostAndRetrieveData: build.mutation<BetaObject, string>({
			query: (queryParams) => ({
				url: `GetV2?Params=${queryParams}`,
				method: 'POST',
			}),
		}),

		GetPermissionsProxyObject: build.query<BetaObject, string>({
			query: (queryParams) => `GetV2?Params=${queryParams}`,
			providesTags: ['Permissions'],
		}),

		GetProxyObject: build.query<BetaObject, string>({
			query: (queryParams) => `GetV2?Params=${queryParams}`,
			providesTags: ['Data'],
		}),

		GetTenantProvisioningConfig: build.mutation<BetaObject, string>({
			query: (customerID) =>
				`GetTenantProvisioningConfig?customerID=${customerID}`,
		}),

		GetSDADDIStatus: build.mutation<BetaObject, string>({
			query: (serviceID) => `GetSDADDIStatus?serviceID=${serviceID}`,
		}),

		TeamsUserManagementPrechecks: build.mutation<BetaObject, string>({
			query: (tenantID) => `TeamsUserManagementPrechecks?tenantID=${tenantID}`,
		}),

		CheckActiveTeamsDROrderSKUsForCustomer: build.mutation<BetaObject, string>({
			query: (customerID) =>
				`CheckActiveTeamsDROrderSKUsForCustomer?customerID=${customerID}`,
		}),

		CheckIfCustomerHasProduct: build.mutation<BetaObject, string>({
			query: (params) => `CheckIfCustomerHasProduct?${params}`,
		}),

		StartSDAConfiguration: build.mutation<string, BetaAPIMutation>({
			query: (apiMutation) => ({
				url: `${apiMutation.QueryParam}`,
				method: 'POST',
				body: apiMutation.BetaObject,
			}),
		}),

		SendCustomersAddedDDIsNotification: build.mutation<string, string>({
			query: (orderID) =>
				`SendCustomersAddedDDIsNotification?orderID=${orderID}`,
		}),

		GetServiceManagementReportAndStatusForCustomer: build.mutation<
			string,
			string
		>({
			query: (customerID) =>
				`GetServiceManagementReportAndStatusForCustomer?customerID=${customerID}`,
		}),

		postCustomerAndReturnSREData: build.mutation<BetaObject, BetaSREMutation>({
			query: (sreMutation) => ({
				url: sreMutation.URL,
				method: 'POST',
				body: sreMutation.BetaObject,
			}),
			invalidatesTags: ['Data'],
		}),

		postSREConfiguration: build.mutation<BetaObject, BetaSREMutation>({
			query: (sreMutation) => ({
				url: sreMutation.URL,
				method: 'POST',
				body: sreMutation.BetaObject,
			}),
		}),

		postCustomerAndReturnEmergencyCallingStatus: build.mutation<
			BetaObject,
			BetaObject
		>({
			query: (sreRequestModel) => ({
				url: 'emergency-calling-status',
				method: 'POST',
				body: sreRequestModel,
			}),
		}),

		postSREEmergencyCallingConfiguration: build.mutation<
			BetaObject,
			BetaObject
		>({
			query: (sreEmergencyCallingModel) => ({
				url: 'configuration/emergencyCalling',
				method: 'POST',
				body: sreEmergencyCallingModel,
			}),
		}),

		CustomAPICall: build.mutation<BetaObject, BetaAPIMutation>({
			query: (apiMutation) => ({
				url: `${apiMutation.QueryParam}`,
				method: 'POST',
				body: apiMutation.BetaObject,
			}),
		}),

		//add ddi ranges
		PostBetaObjectAddDDIRange: build.mutation<BetaObject, BetaObject>({
			query: (reqBody) => ({
				url: 'ddiRange/Add',
				method: 'POST',
				body: reqBody,
			}),
			invalidatesTags: ['Data'],
		}),

		getUsersByOrgID: build.mutation<BetaObject, BetaObject>({
			query: (getUsersByOrgIDRequestModel) => ({
				url: `GetUsersByOrgID`,
				method: 'POST',
				body: getUsersByOrgIDRequestModel,
			}),
		}),

		getMSLicensesForHaloUsers: build.mutation<BetaObject, BetaObject>({
			query: (GetMSLicensesForHaloUsersModel) => ({
				url: `GetMSLicensesForHaloUsers`,
				method: 'POST',
				body: GetMSLicensesForHaloUsersModel,
			}),
		}),

		getUserManagementDataByID: build.mutation<BetaObject, BetaObject>({
			query: (getMembersByOrgIDRequestModel) => ({
				url: `UMOperationsUsingID`,
				method: 'POST',
				body: getMembersByOrgIDRequestModel,
			}),
		}),

		GetAppAndConnServForSRE: build.mutation<BetaObject, BetaObject>({
			query: (pocInstanceRequest) => ({
				url: `trial/GetTrunkAndMSTeamsIDs`,
				method: 'POST',
				body: pocInstanceRequest,
			}),
		}),

		QueueSBCRequest: build.mutation<void, BetaObject>({
			query: (sbcRequestModel) => ({
				url: `/QueueSBCRequest`,
				method: 'POST',
				body: sbcRequestModel,
			}),
		}),

		InviteUsersToPortal: build.mutation<BetaObject, BetaObject>({
			query: (InviteUsersRequest) => ({
				url: `InviteUsersToPortal`,
				method: 'POST',
				body: InviteUsersRequest,
			}),
		}),
		ResendInvite: build.mutation<BetaObject, BetaObject>({
			query: (ResendInviteRequest) => ({
				url: `ResendAuth0Invite`,
				method: 'POST',
				body: ResendInviteRequest,
			}),
		}),

		IncrementSBCVirtualIPRealmCount: build.mutation<string, string>({
			query: (sbcVirtualIPIDs) =>
				`IncrementSBCVirtualIPRealmCount?sbcVirtualIPIDs=${sbcVirtualIPIDs}`,
		}),

		DecrementSBCVirtualIPRealmCount: build.mutation<string, string>({
			query: (sbcVirtualIPIDs) =>
				`DecrementSBCVirtualIPRealmCount?sbcVirtualIPIDs=${sbcVirtualIPIDs}`,
		}),
		// Leads

		GetLeadsByPartner: build.mutation<BetaObject, string>({
			query: (partnerID) => `GetLeadsByPartner?partnerID=${partnerID}`,
		}),
		UpdateLeadStatus: build.mutation<void, BetaObject>({
			query: (updateRequestModel) => ({
				url: `UpdateLeadStatus`,
				method: 'POST',
				body: updateRequestModel,
			}),
		}),

		PublishServiceBusMessage: build.mutation<void, BetaObject>({
			query: (publishServiceBusMessageModel) => ({
				url: `PublishServiceBusMessage`,
				method: 'POST',
				body: publishServiceBusMessageModel,
			}),
		}),

		// POST: Custom backend function to get specific objects (Mainly used for Order MGT and User MGT functions)
		PostBetaObjectAndReturnData: build.mutation<
			BetaObject,
			{ url: string; dataObject: BetaObject }
		>({
			query: ({ url, dataObject }) => ({
				url,
				method: 'POST',
				body: dataObject,
			}),
		}),

		PerformTeamsUserManagementPrechecks: build.mutation<BetaObject, string>({
			query: (tenantID) => `TeamsUserManagementPrechecks?tenantID=${tenantID}`,
		}),

		//POST: Get Order Summary
		PostBetaObjectOrderSummary: build.mutation<BetaObject, BetaObject>({
			query: (summaryReq) => ({
				url: 'GetOrderSummary',
				method: 'POST',
				body: summaryReq,
			}),
			invalidatesTags: ['Data'],
		}),

		//POST: Add new order
		PostBetaObjectAddOrder: build.mutation<BetaObject, BetaObject>({
			query: (addOrderReq) => ({
				url: 'order/AddOrder',
				method: 'POST',
				body: addOrderReq,
			}),
			invalidatesTags: ['Data'],
		}),

		// Add service regions
		PostBetaObjectAddServiceRegions: build.mutation<BetaObject, BetaObject>({
			query: (addServiceRegionsReq) => ({
				url: 'service/regions',
				method: 'POST',
				body: addServiceRegionsReq,
			}),
		}),

		PostBetaObjectRemoveServiceRegions: build.mutation<BetaObject, BetaObject>({
			query: (removeServiceRegionsReq) => ({
				url: 'cleanup/service-region',
				method: 'POST',
				body: removeServiceRegionsReq,
			}),
		}),
	}),
})

export const {
	usePostBetaObjectWithRefetchMutation,
	usePostBetaObjectWithoutRefetchMutation,
	usePostAndRetrieveDataMutation,
	useGetProxyObjectQuery,
	useGetPermissionsProxyObjectQuery,
	useGetTenantProvisioningConfigMutation,
	useSendCustomersAddedDDIsNotificationMutation,
	useGetServiceManagementReportAndStatusForCustomerMutation,
	usePostCustomerAndReturnSREDataMutation,
	usePostSREConfigurationMutation,
	usePostSREEmergencyCallingConfigurationMutation,
	usePostCustomerAndReturnEmergencyCallingStatusMutation,
	useCustomAPICallMutation,
	usePostBetaObjectAddDDIRangeMutation,
	useGetUsersByOrgIDMutation,
	useQueueSBCRequestMutation,
	useResendInviteMutation,
	useGetUserManagementDataByIDMutation,
	useInviteUsersToPortalMutation,
	useIncrementSBCVirtualIPRealmCountMutation,
	useDecrementSBCVirtualIPRealmCountMutation,
	useGetLeadsByPartnerMutation,
	useUpdateLeadStatusMutation,
	useCheckActiveTeamsDROrderSKUsForCustomerMutation,
	useCheckIfCustomerHasProductMutation,
	useGetMSLicensesForHaloUsersMutation,
	useStartSDAConfigurationMutation,
	usePublishServiceBusMessageMutation,
	useGetSDADDIStatusMutation,
	useTeamsUserManagementPrechecksMutation,
	usePostBetaObjectAndReturnDataMutation,
	usePerformTeamsUserManagementPrechecksMutation,
	usePostBetaObjectAddOrderMutation,
	usePostBetaObjectOrderSummaryMutation,
	usePostBetaObjectAddServiceRegionsMutation,
	usePostBetaObjectRemoveServiceRegionsMutation,
} = proxyAPIData
