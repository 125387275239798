import { Alert, Box, Divider, MenuItem, Typography } from '@mui/material'
import EastOutlinedIcon from '@mui/icons-material/EastOutlined'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined'
import '../ConfirmOrder/ConfirmOrder.scss'
import { StyledSelect } from '../../../../../shared/styledComponents/StyledSelect/StyledSelect'
import { useEffect, useRef, useState } from 'react'
import PostAndRetrieveDataHook from '../../../../../../utils/customHooks/APICalls/PostAndRetrieveDataHook'
import {
	Order,
	OrderSKU,
	OrderSKUTerm,
	OrderTerm,
} from '../../../../../../utils/interfaces/DBModels'
import { DataResponse } from '../../../../../../utils/interfaces/APIModels'
import {
	getEnumKeysAsArray,
	isEmptyOrWhitespace,
	showErrorToast,
	showSuccessToast,
} from '../../../../../../utils/helperFunctions/helperFunctions'
import {
	OrderFilterKeyMappings,
	OrderStatuses,
	OrderType,
} from '../../../../../../utils/enums/enums'
import LoadingButton from '@mui/lab/LoadingButton'
import UseCrud from '../../../../../../utils/customHooks/APICalls/UseCrud'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import {
	OrderFilter,
	UpdateRequestOrderType,
} from '../../../../../../utils/interfaces/ComponentModels'

const ChangeOrderType = ({
	orderID,
	onClose,
}: {
	orderID: number
	onClose: (applyFilter?: OrderFilter[]) => void
}) => {
	// Hooks
	const { postAndRetrieveDataFromDB } = PostAndRetrieveDataHook()
	const { modifyData } = UseCrud()

	var currentUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	const [order, setOrder] = useState<Order>()
	const [previousOrdersList, setPreviousOrdersList] = useState<Order[]>([])
	const [selectedPreviousOrder, setSelectedPreviousOrder] = useState<Order>()

	const [selectedOrderType, setSelectedOrderType] = useState<string>('')
	const [selectedPreviousOrderID, setSelectedPreviousOrderID] =
		useState<string>('')
	const [orderTypes, setOrderTypes] = useState<string[]>([])

	const [dataRetrieved, setDataRetrieved] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isConfirmEnabled, setIsConfirmEnabled] = useState(false)
	const [isShowPreviousOrder, setIsShowPreviousOrder] = useState(false)
	const [previousOrderDataRetrieved, setPreviousOrderDataRetrieved] =
		useState(false)
	const [hasPreviousOrderBeenApproved, setHasPreviousOrderBeenApproved] =
		useState(false)

	const noPreviousOrdersFound = useRef(false)
	const customerHasPreviousOrders = useRef(false)
	const maximumPreviousOrders = 3

	useEffect(() => {
		// Get all Order Types and acceptable Order Statuses
		getOrderTypes()
		// Fetch selected order and SKUs linked to it
		fetchOrderAsync()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// *********************************************************** START API Calls *********************************************************** //
	const fetchOrderAsync = async () => {
		// Fetch selected order and SKUs linked to it
		var dataResponse = (await postAndRetrieveDataFromDB(
			'Get Order By ID',
			`Order.OrderSKU.Where(Order.OrderID = '${orderID}')`,
			false,
			false
		)) as DataResponse

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var _order = dataResponse.Obj.OrderList as Order[]
			setSelectedOrderType(OrderType[Number(_order[0].OrderTypeID)])
			setOrder(_order[0])
		}

		setDataRetrieved(true)
	}

	const fetchPreviousOrdersAsync = async () => {
		// Fetch previous orders for the customer (exclude POC orders and currently selected order)
		var dataResponse = (await postAndRetrieveDataFromDB(
			'Get Previous Orders for Customer',
			`Order.OrderSKU.OrderTerm.Where(Order.CustomerID = '${order?.CustomerID}' & Order.OrderID != '${orderID}' & Order.ExternalOrderID !~ 'POC')`,
			false,
			false
		)) as DataResponse

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var _previousOrdersList = dataResponse.Obj.OrderList as Order[]
			customerHasPreviousOrders.current = true // Customer has previous orders even if none are approved
			setPreviousOrdersList(_previousOrdersList) // Set previous orders list
		}

		noPreviousOrdersFound.current = Number(dataResponse.Count) === 0
		setPreviousOrderDataRetrieved(true)
	}

	const fetchPreviousOrderSKUTerms = async (
		_orderSKUs: OrderSKU[]
	): Promise<OrderSKUTerm[]> => {
		var orderSKUTerms: OrderSKUTerm[] = []
		const orderSKUIDs = _orderSKUs.map((item) => item.OrderSKUID)

		var dataResponse = (await postAndRetrieveDataFromDB(
			'Get OrderSKUTerms',
			`OrderSKUTerm.Where(OrderSKUTerm.OrderSKUID ^ '${orderSKUIDs.join(
				','
			)}')`,
			false,
			false
		)) as DataResponse

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var _orderSKUTerms = dataResponse.Obj.OrderSKUTermList as OrderSKUTerm[]
			orderSKUTerms = _orderSKUTerms
		}

		return orderSKUTerms
	}

	const ModifyDBDataAsync = async (
		operation: string,
		requestURL: string,
		objRequest: any
	): Promise<Boolean> => {
		// Modify data in DB
		const dataResponse = (await modifyData({
			FileAndFunctionName: 'ChangeOrderType.tsx confirmOrderType()',
			QueryURL: `${operation}V2?Params=${requestURL}`,
			QueryObj: objRequest,
			ErrorMessage: `Could not update OrderType: operation: ${operation}, requestURL: ${requestURL}, objRequest: ${objRequest}`,
			ShowErrorMessage: false,
			ShowSuccessMessage: false,
			LogErrorToDB: true,
			UserName: currentUser.email,
		})) as Boolean

		return dataResponse
	}

	// *********************************************************** Handle / OnChange Functions *********************************************************** //

	const handleOrderTypeChange = async (_selectedOrderType: string) => {
		// Check if Order Type is different from the currently selected Order Type
		const currentOrderType = OrderType[Number(order?.OrderTypeID)]
		const _isShowPreviousOrder = currentOrderType !== _selectedOrderType

		if (_isShowPreviousOrder && !previousOrderDataRetrieved) {
			// Fetch previous orders for the customer
			await fetchPreviousOrdersAsync()
		}

		setIsShowPreviousOrder(_isShowPreviousOrder)

		// Check if Customer has previous orders
		if (noPreviousOrdersFound.current) {
			// Customer has no previous orders to link to
			setSelectedOrderType(OrderType[Number(order?.OrderTypeID)])
		} else {
			// Customer has previous orders to link to
			setSelectedOrderType(_selectedOrderType)
			const toggleConfirmBtn = handleConfirmOrderTypeToggleButton(
				_selectedOrderType,
				selectedPreviousOrderID
			)
			setIsConfirmEnabled(toggleConfirmBtn)
		}
	}

	const handlePreviousOrderChange = (_selectedPreviousOrderId: string) => {
		setSelectedPreviousOrderID(_selectedPreviousOrderId)
		const _previousOrder = previousOrdersList.find(
			(item) => item.PartnerOrderID === _selectedPreviousOrderId
		)
		setSelectedPreviousOrder(_previousOrder)

		// Check if previous order has been approved
		if (
			_previousOrder?.OrderStatusID === OrderStatuses.InProgress ||
			_previousOrder?.OrderStatusID === OrderStatuses.Active
		) {
			setIsConfirmEnabled(
				handleConfirmOrderTypeToggleButton(
					selectedOrderType,
					_selectedPreviousOrderId
				)
			)
			setHasPreviousOrderBeenApproved(true)
		} else {
			setHasPreviousOrderBeenApproved(false)
		}
	}

	const handleConfirmOrderTypeToggleButton = (
		_orderType: string,
		_previousOrder: string
	): boolean => {
		var shouldEnableConfirm: boolean =
			OrderType[Number(order?.OrderTypeID)] !== _orderType &&
			!isEmptyOrWhitespace(_previousOrder)

		return shouldEnableConfirm
	}

	// *********************************************************** Custom Helper Functions *********************************************************** //

	const getOrderTypes = () => {
		// Get all Order Types (excluding Disconnect)
		const _orderTypes = getEnumKeysAsArray(OrderType, true, new Set())
		// Remove Disconnect from the _orderTypes list
		_orderTypes.splice(_orderTypes.indexOf(OrderType[OrderType.Disconnect]), 1)
		setOrderTypes(_orderTypes)
	}

	const orderHasSKUsInPreviousOrder = (
		_orderSKUList: OrderSKU[],
		_previousOrderSKUList: OrderSKU[]
	): boolean => {
		return _orderSKUList.every((item) =>
			_previousOrderSKUList.some(
				(previousItem) => previousItem.SKUTypeID === item.SKUTypeID
			)
		)
	}

	const getDBUpdateObj = async (): Promise<
		[Order, OrderTerm, OrderSKU[], OrderSKUTerm[]]
	> => {
		const objUpdateOrder: Order = {
			OrderID: order?.OrderID,
			OrderTypeID: OrderType[selectedOrderType as keyof typeof OrderType],
			PreviousOrderID: selectedPreviousOrder?.OrderID,
			CoTerm: true,
		}

		const objUpdateOrderTerm: OrderTerm = {
			Months: selectedPreviousOrder?.OrderTermList?.[0].Months,
			OrderTermID: selectedPreviousOrder?.OrderTermList?.[0].OrderTermID,
			Years: selectedPreviousOrder?.OrderTermList?.[0].Years,
		}

		const _previousOrder = previousOrdersList.find(
			(item) => item.PartnerOrderID === selectedPreviousOrderID
		)
		const _orderSKUList = order?.OrderSKUList as OrderSKU[]
		const _previousOrderSKUList = _previousOrder?.OrderSKUList as OrderSKU[]
		const _orderHasSKUsInPreviousOrder = orderHasSKUsInPreviousOrder(
			_orderSKUList,
			_previousOrderSKUList
		)

		if (!_orderHasSKUsInPreviousOrder) {
			return [objUpdateOrder, objUpdateOrderTerm, [], []]
		}

		var objUpdateOrderSKUList: OrderSKU[] = []
		var objUdateOrderSKUTermList: OrderSKUTerm[] = []

		// Get previous OrderSKUTerms
		const _previousOrderSKUTerms = await fetchPreviousOrderSKUTerms(
			_previousOrderSKUList
		)
		const _currentOrderSKUTerms = await fetchPreviousOrderSKUTerms(
			_orderSKUList
		)

		_orderSKUList.forEach((item: OrderSKU) => {
			_previousOrderSKUList.forEach((previousItem: OrderSKU) => {
				if (item.SKUTypeID === previousItem.SKUTypeID) {
					var objUpdateOrderSKU: OrderSKU = {
						OrderSKUID: item.OrderSKUID,
						SkuTermEndDate: previousItem.SkuTermEndDate,
					}

					// Modify or Trueup (new sku quantity - previous sku quantity)
					if (
						OrderType[selectedOrderType as keyof typeof OrderType] ===
							OrderType.Modify ||
						OrderType[selectedOrderType as keyof typeof OrderType] ===
							OrderType.TrueUp
					) {
						objUpdateOrderSKU.Quantity =
							Number(item.Quantity) - Number(previousItem.Quantity)
					}

					// Renewal
					if (
						OrderType[selectedOrderType as keyof typeof OrderType] ===
						OrderType.Renewal
					) {
						objUpdateOrderSKU.Quantity = item.Quantity
					}
					var currentOrderSKUTerm = _currentOrderSKUTerms.find(
						(skuTerm) => skuTerm.OrderSKUID === item.OrderSKUID
					)
					var previousOrderSKUTerm = _previousOrderSKUTerms.find(
						(skuTerm) => skuTerm.OrderSKUID === previousItem.OrderSKUID
					)

					if (previousOrderSKUTerm && currentOrderSKUTerm) {
						var objUpdateOrderSKUTerm: OrderSKUTerm = {
							OrderSKUTermID: currentOrderSKUTerm.OrderSKUTermID,
							Months: previousOrderSKUTerm.Months,
							Years: previousOrderSKUTerm.Years,
							OrderSKUTermAutoRenewID:
								previousOrderSKUTerm.OrderSKUTermAutoRenewID,
						}
						objUdateOrderSKUTermList.push(objUpdateOrderSKUTerm)
					} else {
						// If SKUs are the same in both orders but current SKU not in OrderSKUTerm
						objUdateOrderSKUTermList.push({
							OrderSKUID: objUpdateOrderSKU.OrderSKUID,
							Months: previousOrderSKUTerm?.Months,
							Years: previousOrderSKUTerm?.Years,
							OrderSKUTermAutoRenewID:
								previousOrderSKUTerm?.OrderSKUTermAutoRenewID,
						})
					}

					objUpdateOrderSKUList.push(objUpdateOrderSKU)
				}
			})
		})

		return [
			objUpdateOrder,
			objUpdateOrderTerm,
			objUpdateOrderSKUList,
			objUdateOrderSKUTermList,
		]
	}

	const updateOrderTypeAsync = async () => {
		var requestURL = `Order,OrderTerm`
		// Get Order, OrderSKU and OrderSKUTerm objects to update
		var [objOrder, objOrderTerm, objOrderSKUList, objOrderSKUTermList] =
			await getDBUpdateObj()
		var objOrderRequest: UpdateRequestOrderType = {
			Order: objOrder,
			OrderTerm: objOrderTerm,
		}

		if (objOrderSKUList.length > 0) {
			requestURL = `${requestURL},OrderSKU:list`
			objOrderRequest.OrderSKUList = objOrderSKUList
		}

		if (objOrderSKUTermList.length > 0) {
			var _orderSKUTermsAdded: Boolean = false
			// how to check if all objOrderSKUTermList have an OrderSKUID
			const hasOrderSKUIDs = objOrderSKUTermList.every(
				(item) => item.OrderSKUID
			)

			// NB: OrderSKUTerms with OrderSKUIDs must be added (these don't exist in DB)
			if (hasOrderSKUIDs) {
				// Get all OrderSKUTerms with OrderSKUIDs, get them from objOrderSKUTermList
				var _orderSKUsToAdd: OrderSKUTerm[] = []
				objOrderSKUTermList.forEach((item) => {
					if (item.OrderSKUID) {
						_orderSKUsToAdd.push(item)
					}
				})

				// filter out _orderSKUsToAdd from objOrderSKUTermList
				objOrderSKUTermList = objOrderSKUTermList.filter(
					(item) =>
						!_orderSKUsToAdd.some(
							(previousItem) => previousItem.OrderSKUID === item.OrderSKUID
						)
				)

				// Add OrderSKUTerms that are not present in DB
				_orderSKUTermsAdded = await ModifyDBDataAsync(
					'Add',
					'OrderSKUTerm:list',
					{ OrderSKUTermList: _orderSKUsToAdd }
				)
			}

			// NB: OrderSKUTerms without OrderSKUIDs must be updated (these exist in DB)

			if (hasOrderSKUIDs && _orderSKUTermsAdded) {
				if (objOrderSKUTermList.length > 0) {
					// Some orderSKUTerms were added, update the existing ones
					requestURL = `${requestURL},OrderSKUTerm:list`
					objOrderRequest.OrderSKUTermList = objOrderSKUTermList
				}
			} else {
				// Some orderSKUTerms were added, update the existing ones
				requestURL = `${requestURL},OrderSKUTerm:list`
				objOrderRequest.OrderSKUTermList = objOrderSKUTermList
			}
		}

		var dataResponse = await ModifyDBDataAsync(
			'Update',
			requestURL,
			objOrderRequest
		)
		return dataResponse
	}

	const confirmOrderType = async () => {
		setIsLoading(true)
		var hasOrderTypeChanged = await updateOrderTypeAsync()
		if (hasOrderTypeChanged) {
			showSuccessToast('Order Type successfully updated')
			const _applyFilter: OrderFilter = {
				Key: OrderFilterKeyMappings.PartnerOrderID,
				Value: order?.PartnerOrderID + '',
			}
			onClose([_applyFilter])
		} else {
			showErrorToast('Could not update Order Type')
		}
		onClose()
		setIsLoading(false)
	}

	const getPreviousOrderDropdownStyles = (
		itemsCount: number
	): React.CSSProperties => {
		// Default menu styles
		const styles: React.CSSProperties = {
			overflowX: 'hidden',
			transition: 'none',
			maxWidth: '450px',
		}

		if (itemsCount > maximumPreviousOrders) {
			styles.maxHeight = '200px'
			styles.overflowY = 'auto'
		}

		return styles
	}

	return dataRetrieved ? (
		<>
			<Box className='change-order-type-container'>
				{/* Heading */}
				<Box className='change-order-type-header'>
					<Box className='header-icons'>
						<Box className='icon' sx={{ bgcolor: 'primary.main' }}>
							<AssignmentLateOutlinedIcon />
						</Box>
						<EastOutlinedIcon />
						<Box className='icon' sx={{ bgcolor: 'success.main' }}>
							<AssignmentTurnedInOutlinedIcon />
						</Box>
					</Box>
					<Box className='header-text'>
						<Typography component='h2'>Change Order Type</Typography>
						<Typography component='p' sx={{ marginTop: -2 }}>
							You can change the order type below
						</Typography>
					</Box>
				</Box>
				{/* Body */}
				<Box className='change-order-type-body'>
					<Divider />
					<Box className='change-order-type-content'>
						{/* Order Type */}
						<Box className='change-order-type-input'>
							<Typography component='p'>
								Order Type: <span style={{ color: 'red' }}>*</span>
							</Typography>
							<StyledSelect
								fullWidth
								required
								disabled={noPreviousOrdersFound.current}
								variant='outlined'
								className='input-field'
								type='text'
								onChange={(e) =>
									handleOrderTypeChange(e.target.value as string)
								}
								value={selectedOrderType}>
								<MenuItem disabled value={0}>
									Choose an order type:
								</MenuItem>
								{orderTypes.map((item, index) => {
									return (
										<MenuItem key={index} value={item}>
											{item}
										</MenuItem>
									)
								})}
							</StyledSelect>
						</Box>
						{/* Previous Order */}
						{isShowPreviousOrder &&
							previousOrderDataRetrieved &&
							previousOrdersList.length > 0 && (
								<Box className='change-order-type-input'>
									<Typography component='p'>
										Previous Order: <span style={{ color: 'red' }}>*</span>
									</Typography>
									<StyledSelect
										fullWidth
										required
										variant='outlined'
										className='input-field'
										type='text'
										onChange={(e) =>
											handlePreviousOrderChange(e.target.value as string)
										}
										value={selectedPreviousOrder}
										MenuProps={{
											PaperProps: {
												style: getPreviousOrderDropdownStyles(
													previousOrdersList.length
												),
											},
										}}>
										<MenuItem disabled value={0}>
											Choose a previous order:
										</MenuItem>
										{previousOrdersList.map((item, index) => {
											return (
												<MenuItem key={index} value={item.PartnerOrderID}>
													{item.PartnerOrderID}
												</MenuItem>
											)
										})}
									</StyledSelect>
								</Box>
							)}

						{/* No Previous Orders */}
						{(noPreviousOrdersFound.current ||
							(previousOrderDataRetrieved &&
								selectedPreviousOrder &&
								!hasPreviousOrderBeenApproved)) && (
							<Box className='no-previous-orders'>
								{/* No previous orders found */}
								{isShowPreviousOrder &&
									previousOrderDataRetrieved &&
									noPreviousOrdersFound.current &&
									!customerHasPreviousOrders.current && (
										<Alert severity='info'>
											No previous orders found for this customer.
										</Alert>
									)}

								{/* Customer has no approved orders to link to */}
								{previousOrderDataRetrieved &&
									customerHasPreviousOrders.current &&
									selectedPreviousOrder &&
									!hasPreviousOrderBeenApproved && (
										<Alert severity='info'>
											Previous order: {selectedPreviousOrder?.PartnerOrderID}{' '}
											has not been approved. Please select an approved order.
										</Alert>
									)}
							</Box>
						)}
					</Box>
				</Box>
				{/* Footer */}
				<Divider />
				<Box className='change-order-type-footer'>
					{noPreviousOrdersFound.current ||
					(previousOrderDataRetrieved &&
						selectedPreviousOrder &&
						!hasPreviousOrderBeenApproved) ? (
						// Cancel button
						<LoadingButton
							variant='contained'
							className='cancel-order-type'
							onClick={() => onClose([])}>
							Cancel
						</LoadingButton>
					) : (
						// Confirm button
						<LoadingButton
							disabled={!isConfirmEnabled}
							variant='contained'
							className='confirm-order-type'
							loading={isLoading}
							onClick={confirmOrderType}>
							Confirm
						</LoadingButton>
					)}
				</Box>
			</Box>
		</>
	) : (
		<Typography component='p'>Loading... Please wait.</Typography>
	)
}

export default ChangeOrderType
